import { Box, Card, Divider, Grid } from "@mui/material";
import { cnpj, cpf } from "cpf-cnpj-validator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  Botao,
  BotaoRetornarListagem,
  InputTextoCEP,
  Loader,
  SelectArredondado
} from "../../../../componentes";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import ModalLuz from "../../../../componentes/modalLuz";
import ModalMensagemErro from "../../../../componentes/ModalMensagemErro";
import {
  estadoCivilList,
  unidadesFederativas
} from "../../../../global/constantes";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import {
  downloadFileAWS,
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskRG,
  removeMaskTelefone,
  validacaoTrimObject
} from "../../../../servicos/utils";
import UploadCard from "../Componentes/UploadCard";
import CadastroAssociacaoHelper from "./helper";
import { useStyles } from "./style";

const AssocicaoLuzCadastro = () => {
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [associacaoId, setAssociacaoId] = useState(null);
  const [carregandoAssociacao, setCarregandoAssociacao] = useState(false);

  const [dadosEmpresa, setDadosEmpresa] = useState({
    id: null,
    cpfCnpj: null,
    razaoSocial: null,
    nomeFantasia: null,
    inscricaoEstadual: null,
    inscricaoMunicipal: null,
    telefone: null,
    situacao: null,
    dataReferencia: null,
    email: null
  });

  const [dadosEndereco, setDadosEndereco] = useState({
    cep: null,
    endereco: null,
    numero: null,
    complemento: null,
    uf: "",
    cidade: null,
    bairro: null
  });

  const [dadosRepresentante, setDadosRepresentante] = useState({
    nomedoPresidente: null,
    cidadaniaPresidente: null,
    estadocivilPresidente: null,
    profissaoPresidente: null,
    rgPresidente: null,
    cpfPresidente: null
  });

  const [modalAcao, setModalAcao] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);
  const [dadosEdicao, setDadosEdicao] = useState({});
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [arquivosAdd, setArquivosAdd] = useState([]);
  const [modalDocumentos, setModalDocumentos] = useState(false);
  const [mensagemModalDocumentos, setMensagemModalDocumentos] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState(null);

  const obterCepCobertura = async (cepCobertura, dataEdit) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.obterCepCobertura(
      cepCobertura,
      setCarregandoCep
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoCep(false);
    }

    setDadosEndereco({
      ...dadosEndereco,
      cep: dataEdit?.cep || data?.cep,
      endereco: dataEdit?.endereco || data?.logradouro,
      numero: dataEdit?.numero || data?.numero,
      complemento: dataEdit?.complemento || data?.complemento,
      uf: dataEdit?.uf ?? data?.uf,
      cidade: dataEdit?.cidade || data?.cidade,
      bairro: dataEdit?.bairro || data?.bairro
    });
  };

  const onBlurCep = async (valorCep) => {
    if (valorCep.length > 7) {
      await obterCepCobertura(removeMaskCep(valorCep), dadosEdicao);
    }
  };

  const onClickEditar = () => {
    history.push(`${RotasDTO.AssociacaoLuz}/cadastro/${associacaoId}`);
    setModalAcao(false);
  };

  const handleDocuments = (tipos, docLuz) => {
    const result = tipos?.map((tipoDoc) => {
      docLuz.find((doc) => {
        if (doc?.tipoId === tipoDoc?.id) {
          tipoDoc.tipoId = doc?.tipoId;
          tipoDoc.nomeArquivo = doc?.nomeArquivo;
          tipoDoc.associacaoId = doc?.associacaoId;
          tipoDoc.guidArquivo = doc?.guidArquivo;
          tipoDoc.docId = doc?.id;
          tipoDoc.dataInicioVigencia = doc?.dataInicioVigencia;
          tipoDoc.dataFinalVigencia = doc?.dataFinalVigencia;
          tipoDoc.situacao = doc?.situacao;
        }
        return false;
      });

      return tipoDoc;
    });

    return result;
  };

  const obterTipoDocumentoAssociacao = async (associacaDoc) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.obterTipoDocumentoAssociacao(
      setCarregandoAssociacao
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
      return;
    }

    setTipoDocumento(handleDocuments(data, associacaDoc || []));
  };

  useEffect(() => {
    if (id) return;
    obterTipoDocumentoAssociacao([]);
  }, [id]);

  const obterAssociacaoLuzPorId = async (idSelecionado) => {
    const model = {
      id: idSelecionado
    };
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.obterAssociacaoLuzPorId(
      model,
      setCarregandoAssociacao
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
    }

    setDadosEdicao(data);
    setDadosEmpresa({
      id: Number(data?.id),
      cpfCnpj: data?.cpfCnpj,
      razaoSocial: data?.razaoSocial,
      nomeFantasia: data?.nomeFantasia,
      inscricaoEstadual: data?.inscricaoEstadual,
      inscricaoMunicipal: data?.inscricaoMunicipal,
      telefone: data?.telefone,
      situacao: data?.situacao,
      email: data?.email
    });

    setDadosRepresentante({
      nomedoPresidente: data?.nomedoPresidente,
      cidadaniaPresidente: data?.cidadaniaPresidente,
      estadocivilPresidente: data?.estadocivilPresidente,
      profissaoPresidente: data?.profissaoPresidente,
      rgPresidente: data?.rgPresidente,
      cpfPresidente: data?.cpfPresidente
    });

    await obterCepCobertura(data?.cep, data);
    await obterTipoDocumentoAssociacao(data?.associacaoLuzDocumentos);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (id) {
      obterAssociacaoLuzPorId(id);
      return () => {};
    }
  }, [id]);

  const buscarDadosCadastraisPorCnpj = async (cnpjEmpresa) => {
    if (!cnpjEmpresa) return;
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.buscarDadosCadastraisPorCnpj(
      cnpjEmpresa,
      setCarregandoNomeFantasia
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoNomeFantasia(false);
      return;
    }
    setDadosEmpresa({
      nomeFantasia: data.data.nomeFantasia,
      razaoSocial: data?.data?.razaoSocial
    });
  };

  const handleEmpresa = (cnpjEmpresa) => {
    setDadosEmpresa({
      cpfCnpj: cnpjEmpresa,
      ...dadosEmpresa
    });
    buscarDadosCadastraisPorCnpj(removeMaskCpfCnpj(cnpjEmpresa));
  };

  const handleEndereco = (e) => {
    setDadosEndereco({
      ...dadosEndereco,
      [e.target.name]: e.target.value
    });
  };

  const handleRepresentate = (e) => {
    setDadosRepresentante({
      ...dadosRepresentante,
      [e.target.name]: e.target.value
    });
  };

  const obterAssociacaoLuzCpfCnpj = async (cnpjEmpresa) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.obterAssociacaoLuzCpfCnpj(
      cnpjEmpresa,
      setCarregandoAssociacao
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
      return;
    }

    setAssociacaoId(data?.id);
    setModalAcao(true);
    setMensagemModal("CNPJ de associação já cadastrada! Deseja Editar?");
  };

  const getBinaryFrom = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));

      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFileToS3 = async (arquivos) => {
    try {
      await arquivos.map(async (item) => {
        const currentFileBuffer = await getBinaryFrom(item?.arquivo?.[0]);
        if (!item.url) return;
        await fetch(decodeURI(item?.url), {
          method: "PUT",
          body: currentFileBuffer,
          headers: {
            "Content-type": "multipart/form-data"
          }
        });
      });

      history.push(`${RotasDTO.AssociacaoLuz}`);
    } catch (error) {
      CadastroAssociacaoHelper.exibirErro("Erro ao fazer upload AWS!");
    }
  };

  const normalizaUpload = (item, urls) => {
    switch (item?.tipoId) {
      case 1:
        return { ...item, url: urls.cartaoCNPJUrl };
      case 2:
        return { ...item, url: urls.contratoSocialUrl };
      case 3:
        return { ...item, url: urls.documentoRepresentanteAssociacaoFrenteUrl };
      case 4:
        return { ...item, url: urls.documentoRepresentanteAssociacaoVersoUrl };
      case 5:
        return { ...item, url: urls.documentoRepresentanteAssociacaoSelfieUrl };
      default:
        return { ...item };
    }
  };

  const gerarUploadDocumentosAssociacao = async (associaoLuzId) => {
    const model = {
      associacaoId: associaoLuzId,
      associacaoLuzUploadDocumentos: arquivosAdd?.map((item) => ({
        tipoId: item?.tipoId,
        nomeArquivo: item?.nomeArquivo
      }))
    };

    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.gerarUploadDocumentosAssociacao(
      model,
      setCarregandoAssociacao
    );

    if (!sucesso) {
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
      return;
    }

    const arquivosNormalizados =
      arquivosAdd?.map((item) => normalizaUpload(item, data)) || [];

    uploadFileToS3(arquivosNormalizados);
  };

  const salvarAssociacaoLuz = async (dados) => {
    const model = {
      uf: dados?.uf,
      cpfCnpj: removeMaskCpfCnpj(dados?.cpfCnpj),
      razaoSocial: dados?.razaoSocial,
      nomeFantasia: dados?.nomeFantasia,
      inscricaoEstadual: dados?.inscricaoEstadual,
      inscricaoMunicipal: dados?.inscricaoMunicipal,
      telefone: removeMaskTelefone(dados?.telefone),
      cep: removeMaskCep(dados?.cep),
      endereco: dados?.endereco,
      numero: dados?.numero,
      complemento: dados?.complemento,
      bairro: dados?.bairro,
      cidade: dados?.cidade,
      situacao: dados?.situacao,
      dataReferencia: window.moment().format(),
      email: dados?.email,
      nomedoPresidente: dados?.nomedoPresidente,
      cidadaniaPresidente: dados?.cidadaniaPresidente,
      estadocivilPresidente: dados?.estadocivilPresidente,
      profissaoPresidente: dados?.profissaoPresidente,
      rgPresidente: removeMaskRG(dados?.rgPresidente),
      cpfPresidente: removeMaskCpfCnpj(dados?.cpfPresidente)
    };

    const {
      data,
      sucesso,
      mensagem
    } = await CadastroAssociacaoHelper.salvarAssociacaoLuz(
      dados?.id,
      validacaoTrimObject(model),
      setCarregandoAssociacao
    );

    if (!sucesso) {
      if (mensagem === "associacaoExists") {
        await obterAssociacaoLuzCpfCnpj(removeMaskCpfCnpj(dados?.cpfCnpj));
        return;
      }
      CadastroAssociacaoHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
      return;
    }

    gerarUploadDocumentosAssociacao(data?.id);
  };

  const enviarFormulario = async (dados) => {
    const doc = tipoDocumento?.map(({ tipoId }) => ({
      tipoId: tipoId ?? null
    }));
    const arq = arquivosAdd.map(({ tipoId }) => ({ tipoId: tipoId ?? null }));

    const documentoValidacao = [...doc, ...arq];

    const documentos =
      documentoValidacao?.filter(
        (item) => item?.tipoId === 1 || item?.tipoId === 2
      ).length > 1;

    if (!documentos) {
      setModalDocumentos(true);
      setMensagemModalDocumentos(
        "Cartão CNPJ e Contrato social são obrigatórios!"
      );
      return;
    }

    const dadosForm = {
      ...dados,
      id: dadosEmpresa?.id,
      uf: dadosEndereco?.uf,
      situacao: dadosEmpresa?.situacao || "AT",
      estadocivilPresidente: dadosRepresentante?.estadocivilPresidente
    };

    salvarAssociacaoLuz(dadosForm);
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const handleModalMensagem = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const addArquivos = async (arquivo, tipo) => {
    setShowLoader(true);
    const fileName = arquivo[0]?.name;
    const arquivoAdd = {
      nomeArquivo: fileName,
      tipoId: tipo,
      arquivo
    };

    setArquivosAdd((prevState) => [arquivoAdd, ...prevState]);

    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  const onDelete = async (_arquivos, tipo) => {
    const arquivoDel = arquivosAdd?.filter((item) => item?.tipoId !== tipo);
    setArquivosAdd(arquivoDel);
  };

  const dowloadFileToS3 = async (nomeArquivo, guidArquivo) => {
    try {
      const model = {
        guid: guidArquivo,
        extensao: nomeArquivo?.split(".")?.reverse()?.[0]
      };
      const {
        data,
        sucesso,
        mensagem
      } = await CadastroAssociacaoHelper.geraUrlDownload(
        model,
        setCarregandoAssociacao
      );

      if (!sucesso) {
        CadastroAssociacaoHelper.exibirErro(mensagem);
        setCarregandoAssociacao(false);
        return;
      }

      downloadFileAWS(data, nomeArquivo);
    } catch (error) {
      CadastroAssociacaoHelper.exibirErro("Error dowload!");
    }
  };

  const mostrarObrigatórios = (tipo, doc) => {
    switch (tipo) {
      case 1:
        return `${doc} *`;
      case 2:
        return `${doc} *`;
      default:
        return doc;
    }
  };

  return (
    <>
      <ModalLuz
        item={modalAcao}
        mensagem={handleModalMensagem(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={onClickEditar}
      />
      <ModalMensagemErro
        item={modalDocumentos}
        titulo=""
        mensagem={handleModalMensagem(mensagemModalDocumentos)}
        onCancelar={() => {
          setModalDocumentos(false);
          setMensagemModalDocumentos("");
        }}
        onConfirmar={() => {}}
        texto=""
        footer={false}
      />
      <Loader loading={carregandoAssociacao}>
        <form
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.cardCadastro}>
            <Box
              component={Grid}
              container
              spacing={2}
              p={2}
              className={classes.container}
            >
              <Grid item lg={6} xs={6} className="font-weight-bold">
                <Box component="div">
                  {id ? "Editar Associação" : "Nova Associação"}
                </Box>
              </Grid>

              <BotaoRetornarListagem
                urlListagem={RotasDTO.AssociacaoLuz}
                zerarCombo
              />

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputMascara
                  type="text"
                  id="cpfCnpj"
                  name="cpfCnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  onBlur={(e) => handleEmpresa(e.target.value)}
                  defaultValue={dadosEmpresa?.cpfCnpj ?? ""}
                  ref={register({
                    required: "Campo CNPJ é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                  })}
                  errors={errors}
                  className={id ? classes.desabledInput : ""}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    permiteValorBranco
                    renderIconShowHide={false}
                    defaultValue={dadosEmpresa?.razaoSocial ?? ""}
                    ref={register({
                      required: "Campo Razão Social é obrigatório!"
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeFantasia"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    permiteValorBranco
                    renderIconShowHide={false}
                    defaultValue={dadosEmpresa?.nomeFantasia ?? ""}
                    ref={register({
                      required: "Campo Nome Fantasia é obrigatório!",
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      },
                      validate: (value) =>
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoEstadual"
                  name="inscricaoEstadual"
                  label="Inscrição Estadual"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.inscricaoEstadual ?? ""}
                  ref={register({
                    required: "Campo Inscrição Estadual é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoMunicipal"
                  name="inscricaoMunicipal"
                  label="Inscrição Municipal"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.inscricaoMunicipal ?? ""}
                  ref={register({
                    required: "Campo Inscrição Municipal é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTelefone
                  type="text"
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.telefone ?? ""}
                  ref={register({
                    required: "O campo Telefone é obrigatório!",
                    minLength: {
                      value: 18,
                      message: "Quantidade mínima de 12 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="email"
                  name="email"
                  label="E-mail"
                  defaultValue={dadosEmpresa?.email ?? ""}
                  permiteValorBranco
                  renderIconShowHide={false}
                  ref={register({
                    required: "O campo E-mail é obrigatório!",
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Campo E-mail inválido!"
                    },
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Box component={Grid} item xs={12} my={2}>
                <Divider />
              </Box>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <InputTextoCEP
                  id="cep"
                  name="cep"
                  label="CEP"
                  carregandoEndereco={carregandoCep}
                  onBlurCep={onBlurCep}
                  defaultValue={dadosEndereco?.cep ?? ""}
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.endereco || ""}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco.numero || ""}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 10,
                      message: "Quantidade máxima de 10 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.complemento || ""}
                  ref={register({
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.bairro || ""}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.cidade || ""}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  valueName="UF"
                  dataSource={unidadesFederativas}
                  label="UF"
                  value={dadosEndereco?.uf ?? ""}
                  onChange={(e) => handleEndereco(e)}
                  placeholder="UF"
                  ref={register(
                    { name: "uf" },
                    {
                      required: !dadosEndereco?.uf
                        ? "Campo UF é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Grid>
              <Box component={Grid} item xs={12}>
                <Divider />
              </Box>
              <Box
                component={Grid}
                item
                xs={12}
                fontSize="24px"
                fontWeight="700"
              >
                Dados Representante
              </Box>

              <Grid item xs={12} sm={4} lg={4}>
                <MaterialInputTexto
                  type="text"
                  id="nomedoPresidente"
                  name="nomedoPresidente"
                  label="Nome"
                  defaultValue={dadosRepresentante?.nomedoPresidente ?? ""}
                  permiteValorBranco
                  renderIconShowHide={false}
                  ref={register}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <MaterialInputTexto
                  type="text"
                  id="cidadaniaPresidente"
                  name="cidadaniaPresidente"
                  label="Nacionalidade"
                  renderIconShowHide={false}
                  defaultValue={dadosRepresentante?.cidadaniaPresidente ?? ""}
                  ref={register}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <SelectArredondado
                  id="estadocivilPresidente"
                  name="estadocivilPresidente"
                  valueKey="value"
                  valueName="label"
                  dataSource={estadoCivilList}
                  label="Estado Civil"
                  value={dadosRepresentante?.estadocivilPresidente ?? ""}
                  onChange={(e) => handleRepresentate(e)}
                  placeholder="Estado Civil"
                  allowClear
                  ref={register({ name: "estadocivilPresidente" })}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <MaterialInputTexto
                  type="text"
                  id="profissaoPresidente"
                  name="profissaoPresidente"
                  label="Profissão"
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={dadosRepresentante?.profissaoPresidente || ""}
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      // eslint-disable-next-line quotes
                      (!value.includes("'") && !value.includes('"')) ||
                      "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <MaterialInputTexto
                  type="text"
                  id="rgPresidente"
                  name="rgPresidente"
                  label="RG"
                  renderIconShowHide={false}
                  defaultValue={dadosRepresentante?.rgPresidente ?? ""}
                  ref={register({
                    maxLength: {
                      value: 20,
                      message: "Quantidade máxima de 20 caracteres!"
                    },
                    minLength: {
                      value: 7,
                      message: "Quantidade mínima de 7 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <MaterialInputMascara
                  type="text"
                  id="cpfPresidente"
                  name="cpfPresidente"
                  label="CPF"
                  mask="###.###.###-##"
                  renderIconShowHide={false}
                  defaultValue={dadosRepresentante?.cpfPresidente ?? ""}
                  ref={register({
                    maxLength: {
                      value: 14,
                      message: "Quantidade máxima de 14 caracteres!"
                    },
                    validate: (value) =>
                      value ? cpf.isValid(value) || "CPF inválido!" : true
                  })}
                  errors={errors}
                />
              </Grid>

              <Box component={Grid} item xs={12}>
                <Divider />
              </Box>

              <Box
                component={Grid}
                item
                xs={12}
                my={2}
                fontSize="24px"
                fontWeight="700"
              >
                Inserir documentos
              </Box>

              {tipoDocumento &&
                tipoDocumento.map((tipoDoc, index) => (
                  <>
                    <Grid key={index} item xs={6}>
                      <UploadCard
                        showLoader={
                          tipoSelecionado === tipoDoc?.id ? showLoader : false
                        }
                        limite={1}
                        titulo=""
                        tituloUpload={mostrarObrigatórios(
                          tipoDoc?.id,
                          tipoDoc?.tipo
                        )}
                        onAddArquivoUpload={(arquivos, tipo) => {
                          addArquivos(arquivos, tipo);
                          setTipoSelecionado(tipo);
                        }}
                        onDeleteArquivoUpload={(arquivos, tipo) => {
                          onDelete(arquivos, tipo);
                        }}
                        renderizaComponente
                        tipo={tipoDoc?.id}
                        initialFiles={
                          tipoDoc.nomeArquivo && [
                            new File([""], tipoDoc.nomeArquivo, {
                              type: `application/${
                                tipoDoc.nomeArquivo?.split(".")?.reverse()?.[0]
                              }`,
                              lastModified: new Date()
                            })
                          ]
                        }
                        onClickDownload={(nome) =>
                          dowloadFileToS3(nome, tipoDoc.guidArquivo)
                        }
                        iconeDesabled={!!tipoDoc.tipoId}
                      />
                      <Divider
                        sx={{
                          background: "#9F9F9F",
                          display: `${
                            tipoDocumento?.at(-1).id === tipoDoc.id
                              ? "none"
                              : ""
                          }`
                        }}
                      />
                    </Grid>
                  </>
                ))}

              <Grid item xs={12} mt="-15px">
                <Divider sx={{ background: "#9F9F9F" }} />
              </Grid>

              <Grid item xs={12} />

              <Grid item lg={3} md={4} sm={4} xs={12}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Grid>
            </Box>
          </Card>
        </form>
      </Loader>
    </>
  );
};

export default AssocicaoLuzCadastro;
