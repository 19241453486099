import { Buffer } from "buffer";
import { obterSerasaReduzido } from "servicos/serasaServico";
import RadioDto from "../../../../../componentes/inputRadioGroup/radioDto";
import RetornoEndpointDto from "../../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import {
  buscarPorId,
  listarOfficersPorArvoreGerencial
} from "../../../../../servicos/colaboradoresServico";
import { obterCepCobertura } from "../../../../../servicos/correiosServico";
import {
  atualizarExternoIdDeltaProcessamento,
  digitalizacaoFaturaDeltaProcessamentoOcr,
  gerarUrlUpload,
  obterbaixaRendaDeltaProcessamento,
  obterFaturaDeltaProcessamentoOcr
} from "../../../../../servicos/deltaProcessamentoOcrServico";
import {
  buscarDistribuidoraNomeOuCnpj,
  buscarDistribuidoras,
  buscarPorId as obterDistribuidoraPorId
} from "../../../../../servicos/distribuidorasServico";
import {
  atualizarSalesForce,
  criarCotacaoGD,
  edicaoDesconto,
  obterCotacaoGd,
  obterCotacaoGdId,
  obterCotacaoGdIdSemFatura,
  obterPorCupom
} from "../../../../../servicos/leadClienteLuzServico";
import { obterCotacoesIdRepresentantes } from "../../../../../servicos/leadClientesServico";
import { obterMatrizDescontoGdBaixa } from "../../../../../servicos/matrizDescontoGdBaixaServico";
import { obterClienteCupomIndicadorPorCodigo } from "../../../../../servicos/memberGetMemberServico";
import { criarLeadSalesForceServico } from "../../../../../servicos/preLeadSalesforceServico";
import {
  buscarNivelTensao,
  buscarNumeroFases
} from "../../../../../servicos/unidadesServico";

export default class CotacaoGDHelper {
  static async obterOfficerArvore(empresa, setCarregandoOfficer, setError) {
    try {
      setCarregandoOfficer(true);
      const resultado = await listarOfficersPorArvoreGerencial(empresa ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Officers não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Officers obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Officers não encontrados empresa ${empresa}`
      );
    } finally {
      setCarregandoOfficer(false);
    }
  }

  static async obterColaboradorId(colaboradorId, setError) {
    try {
      const resultado = await buscarPorId(colaboradorId ?? 0);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Colaborador por id não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Colaborador por id obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 403) {
        return RetornoEndpointDto.Erro("403");
      }
      setError(true);
      if (error?.response?.status === 403) {
        return RetornoEndpointDto.Erro("403");
      }
      return RetornoEndpointDto.Erro(
        `Não foi  encontrado colaborador id ${colaboradorId}`
      );
    }
  }

  static async digitalizacaoFaturaDeltaProcessamentoOcr(
    fileBytes,
    fileName,
    externoId,
    empresaCodigo,
    setCarregandoArquivo,
    setError,
    colaboradorId,
    distribuidoraIdModal
  ) {
    try {
      setCarregandoArquivo(true);

      const keyOnS3 = `${externoId}-${fileName}`;
      const urlPreSigned = await gerarUrlUpload(keyOnS3);
      const binary = Buffer.from(fileBytes, "base64");

      await fetch(decodeURI(urlPreSigned.data), {
        method: "PUT",
        body: binary.buffer,
        headers: {
          "Content-type": "application/octet-stream"
        }
      });
      const resultado = await digitalizacaoFaturaDeltaProcessamentoOcr(
        keyOnS3,
        externoId,
        empresaCodigo,
        colaboradorId,
        distribuidoraIdModal
      );

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Erro ao efetuar upload!");

      const respostaFatura = await new Promise((resolve) => {
        const intervalId = setInterval(async () => {
          try {
            const res = await obterFaturaDeltaProcessamentoOcr(
              resultado.data.resultObject.item2
            );

            if (res.status !== 202) {
              clearInterval(intervalId);
              resolve(res);
            }
          } catch (error) {
            clearInterval(intervalId);
            resolve(error);
          }
        }, 5000);
      });

      if (
        respostaFatura?.data?.statusCodes === 200 &&
        respostaFatura?.data?.success
      ) {
        return RetornoEndpointDto.Sucesso(
          "Sucesso ao efetuar upload!",
          respostaFatura?.data
        );
      }
      setError(true);
      return RetornoEndpointDto.Erro(
        `Erro ao fazer upload do arquivo ${fileName}`
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        `Erro ao fazer upload do arquivo ${fileName}`
      );
    } finally {
      setCarregandoArquivo(false);
    }
  }

  static obterTipoCliente() {
    return [
      new RadioDto("Pessoa Física", "PF"),
      new RadioDto("Pessoa Jurídica", "PJ")
    ];
  }

  static obterTipoPlano() {
    return [new RadioDto("Padrão", "PD"), new RadioDto("Personalizado", "PS")];
  }

  static async obterDistribuidora(setCarregandoDistribuidora, setError) {
    try {
      setCarregandoDistribuidora(true);
      const resultado = await buscarDistribuidoras();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Distribuidora obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Distribuidora não encontrados!");
    } finally {
      setCarregandoDistribuidora(false);
    }
  }

  static async obterNumerosFases(setCarregandoFases, setError) {
    try {
      setCarregandoFases(true);
      const resultado = await buscarNumeroFases();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Números de fases não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Números de fases obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Números de fases não encontrados!");
    } finally {
      setCarregandoFases(false);
    }
  }

  static async obterNivelTensao(setCarregandoNivelTensao, setError) {
    try {
      setCarregandoNivelTensao(true);
      const resultado = await buscarNivelTensao();
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Níveis de tensão obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Níveis de tensão não encontrados!");
    } finally {
      setCarregandoNivelTensao(false);
    }
  }

  static async obterDistribuidoraCnpjOuNome(cnpj, setError) {
    try {
      const resultado = await buscarDistribuidoraNomeOuCnpj(cnpj);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter distribuídora única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter distribuídora única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Obter distribuídora única não encontrados!"
      );
    }
  }

  static async obterCepCobertura(cepCobertura, setCarregandoCep, setError) {
    try {
      setCarregandoCep(true);
      const resultado = await obterCepCobertura(cepCobertura);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoCep(false);
    }
  }

  static async obterMatrizDescontoGdBaixa(
    params,
    setCarregandoMatriz,
    setError
  ) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterMatrizDescontoGdBaixa(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Obter cobertura única não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Obter cobertura única com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Obter cobertura única não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async criarLeadSalesForceServico(
    params,
    setCarregandoMatriz,
    setError,
    id
  ) {
    try {
      setCarregandoMatriz(true);
      const resultado = await criarLeadSalesForceServico(params, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead sales force não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead sales force criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Lead sales force não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async atualizarExternoIdDeltaProcessamento(
    externoIdOrigem,
    externoIdDestino,
    setError,
    setCarregandoMatriz
  ) {
    try {
      setCarregandoMatriz(true);
      const resultado = await atualizarExternoIdDeltaProcessamento(
        externoIdOrigem,
        externoIdDestino
      );
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead sales forces id atualizado não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead sales forces id atualizado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar lead sales forces id luz"
      );
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterbaixaRendaDeltaProcessamento(params) {
    try {
      const resultado = await obterbaixaRendaDeltaProcessamento(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Baixa Renda não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Baixa Renda obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Baixa renda não encontrados!");
    }
  }

  static async atualizarSalesForce(body, setCarregandoMatriz, setError) {
    try {
      setCarregandoMatriz(true);
      const resultado = await atualizarSalesForce(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead sales forces atualizado não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead sales forces atualizado criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao atualizar lead sales forces luz");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterCotacaoGdId(id, setCarregandoMatriz) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterCotacaoGdId(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("PLanos não encontrados!");
      return RetornoEndpointDto.Sucesso(
        "PLanos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterCotacaoGd(model, setCarregandoMatriz) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterCotacaoGd(model);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("CotacaoGDEncontrada");
      return RetornoEndpointDto.Sucesso(
        "Cotação gd obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterCotacoesIdRepresentantes(id) {
    try {
      const resultado = await obterCotacoesIdRepresentantes(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Representantes não encontrada!");
      return RetornoEndpointDto.Sucesso(
        "Representates obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar representantes!");
    }
  }

  static async criarCotacaoGD(body, setCarregandoMatriz, id) {
    try {
      setCarregandoMatriz(true);
      const resultado = await criarCotacaoGD(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação GD não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cotação GD criado com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao criar cotação GD");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterCotacaoGdIdSemFatura(id, setCarregandoMatriz) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterCotacaoGdIdSemFatura(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Fatura não encontrada!");
      return RetornoEndpointDto.Sucesso(
        "PLanos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Fatura não encontrada!");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterPorCupom(cupom, setCarregandoCupom) {
    try {
      setCarregandoCupom(true);
      const resultado = await obterPorCupom(cupom);
      if (resultado.status === 204) return RetornoEndpointDto.Erro("204");
      return RetornoEndpointDto.Sucesso("Cupom válido", resultado.data);
    } catch (error) {
      return RetornoEndpointDto.Erro("Cupom não válido!");
    } finally {
      setCarregandoCupom(false);
    }
  }

  static async obterDistribuidoraPorId(id) {
    try {
      // setCarregandoCupom(true);
      const resultado = await obterDistribuidoraPorId(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Distribuidora não encontrada!");
      return RetornoEndpointDto.Sucesso(
        "Distribuidora encontrada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar distribuidora!");
    } finally {
      // setCarregandoCupom(false);
    }
  }

  static async obterClienteCupomIndicadorPorCodigo(params, setCarregandoCupom) {
    try {
      setCarregandoCupom(true);
      const resultado = await obterClienteCupomIndicadorPorCodigo(params);
      if (resultado.status === 204) return RetornoEndpointDto.Erro("204");
      return RetornoEndpointDto.Sucesso("Cupom válido", resultado.data);
    } catch (error) {
      return RetornoEndpointDto.Erro("Cupom não válido!");
    } finally {
      setCarregandoCupom(false);
    }
  }

  static async edicaoDesconto(body, setCarregandoMatriz) {
    try {
      setCarregandoMatriz(true);
      const resultado = await edicaoDesconto(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Edição não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Edição de descontos efetuada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao editar descontos");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static async obterSerasaReduzido(params, setCarregandoMatriz) {
    try {
      setCarregandoMatriz(true);
      const resultado = await obterSerasaReduzido(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Serasa não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Serasa encontrado com sucesso",
        resultado.data
      );
    } catch (error) {
      if (error?.response?.status === 400) {
        return RetornoEndpointDto.Sucesso("400", {
          status: false,
          nomeStatusSerasa: null,
          probabilidadeInadimplencia: null,
          score: null
        });
      }

      return RetornoEndpointDto.Erro("Erro ao encontrar serasa");
    } finally {
      setCarregandoMatriz(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
