import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid } from "@mui/material";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialSwitch from "../../../../componentes/switch/index";

// Styles
import { useStyles } from "./style";

const PrimeiraLinha = ({ register, medidor, errors, podeEditarCampos }) => {
  return (
    <Grid container px={2} spacing={4}>
      <Grid item lg={3} md={3} sm={3}>
        <MaterialInputTexto
          type="text"
          id="CodMedidorCCEE"
          name="CodMedidorCCEE"
          label="Código Medidor CCEE"
          labelWidth="50"
          renderIconShowHide={false}
          defaultValue={medidor?.codigoMedidorCCEE}
          ref={register({
            required: "Campo Código Medidor CCEE é obrigatório!",
            maxLength: {
              value: 50,
              message: "Quantidade máxima de 50 caracteres!"
            }
          })}
          errors={errors}
          disabled={!podeEditarCampos}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={3}>
        <MaterialInputTexto
          type="number"
          id="CodPerfilSCDE"
          name="CodPerfilSCDE"
          label="Código Perfil SCDE"
          labelWidth="110"
          renderIconShowHide={false}
          defaultValue={medidor?.codigoPerfilSCDE}
          ref={register({
            required: "Campo Código Perfil SCDE é obrigatório!",
            min: {
              value: 1,
              message: "Campo Código Perfil SCDE não pode ser negativo!"
            }
          })}
          errors={errors}
          disabled={!podeEditarCampos}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6}>
        <MaterialInputTexto
          type="text"
          id="DescricaoMedidorCCEE"
          name="DescricaoMedidorCCEE"
          label="Descrição"
          labelWidth="110"
          renderIconShowHide={false}
          defaultValue={medidor?.descricaoMedidorCCEE}
          ref={register({
            required: "Campo Descrição é obrigatório!",
            maxLength: {
              value: 100,
              message: "Quantidade máxima de 100 caracteres!"
            }
          })}
          errors={errors}
          disabled={!podeEditarCampos}
        />
      </Grid>
    </Grid>
  );
};

PrimeiraLinha.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  medidor: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.any]),
  podeEditarCampos: PropTypes.oneOfType([PropTypes.bool]).isRequired
};

PrimeiraLinha.defaultProps = {
  medidor: {},
  errors: {}
};

const SegundaLinha = ({ register, medidor, errors, podeEditarCampos }) => {
  const [checkedMedidorAtivo, setCheckedMedidorAtivo] = useState(false);
  const [checkedPardaCompartilhada, setCheckedPardaCompartilhada] = useState(
    false
  );

  const classes = useStyles();

  useEffect(() => {
    if (
      medidor?.porcentagemPerdaCompartilhadaConsumo !== 0 &&
      medidor?.porcentagemPerdaCompartilhadaConsumo !== undefined
    )
      setCheckedPardaCompartilhada(true);
    if (medidor?.situacao === "AT") setCheckedMedidorAtivo(true);
  }, [medidor]);

  const onChangeMedidorAtivo = (e) => {
    setCheckedMedidorAtivo(e);
  };

  const onChangePardaCompartilhada = (e) => {
    setCheckedPardaCompartilhada(e);
  };

  return (
    <Grid container p={2} spacing={4}>
      {medidor?.id ? (
        <Grid item lg={3} md={3} sm={3}>
          <MaterialSwitch
            label="Ativo"
            labelPlacement="top"
            id="swMedidorAtivo;"
            name="swMedidorAtivo"
            ref={register()}
            checked={checkedMedidorAtivo}
            onChange={(valor) => onChangeMedidorAtivo(valor)}
            errors={errors}
            disabled={!podeEditarCampos}
          />
        </Grid>
      ) : null}
      <Grid item lg={3} md={3} sm={3}>
        <MaterialSwitch
          label="Perda Compartilhada?"
          labelPlacement="top"
          id="swPerdaCompartilhada"
          name="swPerdaCompartilhada"
          ref={register()}
          checked={checkedPardaCompartilhada}
          onChange={(valor) => onChangePardaCompartilhada(valor)}
          errors={errors}
          disabled={!podeEditarCampos}
        />
      </Grid>
      {checkedPardaCompartilhada ? (
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          className={classes.containerPerdaCompartilhada}
        >
          <MaterialInputTexto
            type="number"
            id="PerdaCompartilhada"
            name="PerdaCompartilhada"
            label="Perda Compartilhada"
            labelWidth="110"
            renderIconShowHide={false}
            defaultValue={medidor?.porcentagemPerdaCompartilhadaConsumo}
            ref={register({
              min: {
                value: 1,
                message: "Campo Perda Compartilhada não pode ser negativo!"
              }
            })}
            errors={errors}
            disabled={!podeEditarCampos}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

SegundaLinha.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  medidor: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.any]),
  podeEditarCampos: PropTypes.oneOfType([PropTypes.bool]).isRequired
};

SegundaLinha.defaultProps = {
  medidor: {},
  errors: {}
};

const Campos = ({ register, medidor, errors, podeEditarCampos }) => {
  return (
    <>
      <PrimeiraLinha
        register={register}
        medidor={medidor}
        errors={errors}
        podeEditarCampos={podeEditarCampos}
      />
      <SegundaLinha
        register={register}
        medidor={medidor}
        errors={errors}
        podeEditarCampos={podeEditarCampos}
      />
    </>
  );
};

Campos.propTypes = {
  register: PropTypes.oneOfType([PropTypes.any]).isRequired,
  medidor: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.any]),
  podeEditarCampos: PropTypes.oneOfType([PropTypes.bool]).isRequired
};

Campos.defaultProps = {
  medidor: {},
  errors: {}
};

export default Campos;
