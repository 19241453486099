import UnidadeFederativaDto from "../dto/unidadeFederativaDto";
import EnumDto from "../dto/enumDto";

export const unidadesFederativas = [
  new UnidadeFederativaDto("Acre", "AC", 1),
  new UnidadeFederativaDto("Alagoas", "AL", 3),
  new UnidadeFederativaDto("Amapá", "AP", 4),
  new UnidadeFederativaDto("Amazonas", "AM", 4),
  new UnidadeFederativaDto("Bahia", "BA", 3),
  new UnidadeFederativaDto("Ceará", "CE", 3),
  new UnidadeFederativaDto("Distrito Federal", "DF", 1),
  new UnidadeFederativaDto("Espírito Santo", "ES", 1),
  new UnidadeFederativaDto("Goiás", "GO", 1),
  new UnidadeFederativaDto("Maranhão", "MA", 4),
  new UnidadeFederativaDto("Mato Grosso", "MT", 1),
  new UnidadeFederativaDto("Mato Grosso do Sul", "MS", 1),
  new UnidadeFederativaDto("Minas Gerais", "MG", 1),
  new UnidadeFederativaDto("Pará", "PA", 4),
  new UnidadeFederativaDto("Paraíba", "PB", 3),
  new UnidadeFederativaDto("Paraná", "PR", 2),
  new UnidadeFederativaDto("Pernambuco", "PE", 3),
  new UnidadeFederativaDto("Piauí", "PI", 3),
  new UnidadeFederativaDto("Rio de Janeiro", "RJ", 1),
  new UnidadeFederativaDto("Rio Grande do Norte", "RN", 3),
  new UnidadeFederativaDto("Rio Grande do Sul", "RS", 2),
  new UnidadeFederativaDto("Rondônia", "RO", 1),
  new UnidadeFederativaDto("Roraima", "RR", 0),
  new UnidadeFederativaDto("Santa Catarina", "SC", 2),
  new UnidadeFederativaDto("São Paulo", "SP", 1),
  new UnidadeFederativaDto("Sergipe", "SE", 3),
  new UnidadeFederativaDto("Tocantins", "TO", 4)
];

export const usuariosTipo = [
  new EnumDto(1, "Colaborador", "Colaborador", "Colaborador"),
  new EnumDto(2, "Cliente", "Cliente", "Cliente"),
  new EnumDto(3, "Prospector", "Prospect", "Prospect")
];

export const usuarioTipoEnum = {
  colaborador: 1,
  cliente: 2,
  prospector: 3
};

export const projecoesTipo = {
  mediaSimples: 1,
  diasSemanaHora: 2,
  diaUtilNaoUtil: 3,
  mesesAnteriores: 4,
  horasDePontaEForaPonta: 5
};

export const cotacaoTipoCalculo = {
  consumoMedio: 1,
  valorMedio: 2
};

export const tipoArquivoWorkFlow = {
  ContratoSocialProcuracao: 1,
  FaturasCCER: 2,
  FaturasEnergia: 3,
  BalancoDre: 4
};

export const tipoClienteDocumento = {
  Identificacao: 1,
  FotoIdentificação: 2,
  Contrato: 3,
  TermoAdesao: 4
};

export const tipoEnvioContrato = {
  Email: 1,
  SMS: 2,
  AssinarPlataforma: 3
};

export const ProtocolosEtapas = {
  IndicarRepresentanteLegal: 100002197,
  AssinarContratoProcuracao: 100002198,
  PrevioAnaliseRisco: 100002088,
  CreditoAprovadoAutomatico: 100002090,
  CreditoRejeitadoAutomatico: 100002089,
  ReprovadoDefault: 100002093,
  AnaliseCreditoManual: 100002092,
  CreditoAprovadoManual: 100002099,
  CreditoRejeitadoManual: 100002098,
  Impossibilidade: 100002114,
  AssinaturaConfirmacaoContrato: 100002146,
  ValidacaoAssinaturaConfirmacaoContrato: 100002147,
  CartaDenuncia: 100002155,
  TermoPactuacao: 100002158,
  ModelagemCCEE: 100002167,
  ImportacaoFaturas: 100002173,
  ValidacaoImportacaoFaturas: 100002175,
  CadastroMedidor: 100002178,
  Fim: 100002183,
  AguardandoAssinaturaContrato: "AguardandoAssinaturaContrato",
  AguardandoAssinaturaConfirmacao: "AguardandoAssinaturaConfirmacao",
  Migracao: "Migracao",
  EnvioDocumentos: 100009996,
  AnaliseSeguranca: 100009997,
  AssinaturaContrato: 100009998,
  InstalacaoMedidor: 100009999
};

export const estadoCivilList = [
  { label: "Solteiro", value: "solteiro" },
  { label: "Casado", value: "casado" },
  { label: "Separado", value: "separado" },
  { label: "Divorciado", value: "divorciado" },
  { label: "Viúvo", value: "viúvo" }
];

export const tipoUnidadeConsumidoraList = [
  { label: "Rural", value: "rural" },
  { label: "Industrial", value: "industrial" },
  { label: "Comercial", value: "comercial" }
];

export const confirmacao = [
  { id: 1, sigla: "S", descricao: "SIM" },
  { id: 2, sigla: "N", descricao: "NÃO" }
];

export const sexoList = [
  { label: "Masculino", value: "Masculino" },
  { label: "Feminino", value: "Feminino" },
  { label: "Não se identifica", value: "NaoSeIdentifica" }
];

export const cmainhoFuncionalidadeDefaultEnum = {
  unidadesConsumidoras: 39
};

export const meses = [
  {
    label: "Janeiro",
    value: 1
  },
  {
    label: "Fevereiro",
    value: 2
  },
  {
    label: "Março",
    value: 3
  },
  {
    label: "Abril",
    value: 4
  },
  {
    label: "Maio",
    value: 5
  },
  {
    label: "Junho",
    value: 6
  },
  {
    label: "Julho",
    value: 7
  },
  {
    label: "Agosto",
    value: 8
  },
  {
    label: "Setembro",
    value: 9
  },
  {
    label: "Outubro",
    value: 10
  },
  {
    label: "Novembro",
    value: 11
  },
  {
    label: "Dezembro",
    value: 12
  }
];

export const somaParcelas = (initial = 0, length) => {
  const array = [];
  for (let index = initial; index <= length; index++) {
    array.push({ label: `${index}`, value: index });
  }
  return array;
};
