import { createGlobalStyle } from "styled-components";
import theme from "../themes";

export default createGlobalStyle`
    @font-face {
        font-family: ${theme.font.family};
        src: local(${theme.font.family}), url(${theme.font.srcFont});
    }
    @font-face {
        font-family: ${theme.fontTahoma.family};
        src: url(${theme.fontTahoma.srcFont});
        font-weight: normal;
        font-style: normal;
    }
    body {
        color: ${theme.color.primaryBackgroud} !important;
        font-family: ${theme.font.family} !important;
    }
    * {
        font-family: ${theme.font.family} !important;
    }
    .MuiOutlinedInput-root {
        border-radius:${theme.borda.input}
    }
`;
