import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.PROFILE);

// Efetua login
export const efetuarLogin = async (dados, validacaoCaptcha) => {
  return api().post("v1/Login/Authenticate", {
    NomeUsuario: dados.acesso,
    Email: dados.acesso,
    CnpjCpf: dados.acesso,
    Senha: dados.senha,
    validacaoCaptcha: validacaoCaptcha
  });
};

// Captcha 
export const retornoCaptcha = async () => {
  return api().get("v1/Captcha");
};

// Validar Captcha 
export const validarCaptcha = async (validacaoCaptcha) => {
  return api().post("v1/Captcha/validar", {
    chave: validacaoCaptcha.chave,
    posicao: validacaoCaptcha.posicao,
  });
};

// Obtém as contas do usuário depois do login
export const obterContasUsuario = async (id) => {
  return api().get(`v1/UsuarioUnidade?usuarioId=${id}`);
};

// Checa o token de alteração de senha
export const validarChave = async (chave) => {
  return api().post(`v1/Usuarios/validarChave/${chave}`);
};

// Ativa senha do usuário
export const ativarUsuario = async (dados) => {
  return api().patch("v1/Usuarios/ativarusuario", dados);
};

// Realiza a troca de senha
export const atualizarSenha = (dados) => {
  return api().patch("v1/Usuarios/password", dados);
};

// Solicita link de troca de senha
export const regerarChave = async (email) => {
  return api().post(`v1/Usuarios/${email}/regerarChave`);
};

// Listar permissões do usuário
export const listarPermissoes = (token) => {
  return api().get("v1/Permissao", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const obterUsuariosClientes = async (id) => {
  return api().get(`v1/Usuarios/app/${id}`);
};
