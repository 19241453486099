import { cnpj, cpf } from "cpf-cnpj-validator";
import { removeMaskCpfCnpj } from "../../servicos/utils";

const mascaraCpf = (valor) => {
  if (!valor) return "";
  return valor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};
const mascaraCnpj = (valor) => {
  if (!valor) return "";
  return valor?.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

export const formatarCampo = (value) => {
  let mask = "";
  if (value?.length <= 11) {
    mask = mascaraCpf(value);
  } else {
    mask = mascaraCnpj(value);
  }

  return mask;
};

export const validarCpfCnpj = (value) => {
  let validacao;
  if (value?.length <= 14) validacao = cpf.isValid(value) || "CPF inválido!";
  else validacao = cnpj.isValid(value) || "CNPJ inválido!";

  return validacao;
};

export const cpfCnpjValido = (value) => {
  if (!value) return "";
  if (cpf.isValid(value) || cnpj.isValid(value)) {
    value = removeMaskCpfCnpj(value);
  }

  return value;
};

export const validarCpf = (value) => {
  if (!value) return "";
  return cpf.isValid(value) || "CPF inválido!";
};
