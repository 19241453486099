import React, { useState, useEffect, useMemo } from "react";
import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CheckInputs from "paginas/CotacaoGD/CotacaoInicialGD/componentes/CheckInputs";
import { existeAcento } from "../../../../../../../servicos/utils";
import { validarCpfCnpj } from "../../../../../../../global/utils/formatCpfCnpj";
import MaterialInputTexto from "../../../../../../../componentes/inputTexto/materialInput";
import MaterialInputMascara from "../../../../../../../componentes/inputTextoMascara";
import MaterialInputTelefone from "../../../../../../../componentes/inputTexto/materialInputTelefone";

import { useStyles } from "./style";
import MaterialInputMascaraCpf from "../inputMaskCpf";
import { usuarioPossuiFuncionalidade } from "../../../../../../../servicos/funcionalidadesServico";
import { RotasDTO } from "../../../../../../../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "../../../../../../../global/dto/interfacesDto";

const InformacaoEmpresa = ({
  nomeContratante,
  emailContratante,
  telefoneContratante,
  register,
  errors,
  clearErrors,
  setValue,
  cpfCliente,
  nomeEmpresa,
  enviarForm,
  representantes,
  editarInputs,
  statusVigenciaFechado,
  enviarFormProposta
}) => {
  const classes = useStyles();
  const themeMobile = useTheme();

  const [nomeEmpresaContratante, setNomeEmpresaContrante] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [nomeRepresentante, setRepresentante] = useState("");
  const [emailRepresentante, setEmailRepresentante] = useState("");
  const [telefoneRepresentante, setTelefoneRepresentante] = useState("");
  const [cpfRepresentante, setCpfRepresentante] = useState("");

  const [nomeRepresentante2, setRepresentante2] = useState("");
  const [emailRepresentante2, setEmail2] = useState("");
  const [telefoneRepresentante2, setTelefone2] = useState("");
  const [cpfRepresentante2, setCpfRepresentante2] = useState("");

  const [camposAdicionais, setCamposAdicionais] = useState(false);
  const [checked, setChecked] = useState(false);
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const mobileHabilitar = useMediaQuery(themeMobile.breakpoints.down("sm"));

  const podeEditarCpfCnpj = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeEditarCPFCNPJ
  );

  const habiltarEditar = useMemo(() => {
    return podeEditarCpfCnpj && checked;
  }, [podeEditarCpfCnpj, checked]);

  useEffect(() => {
    setNomeEmpresaContrante(nomeEmpresa);
    setRepresentante(nomeContratante);
    setEmailRepresentante(emailContratante);
    setTelefoneRepresentante(telefoneContratante);
  }, [nomeContratante, emailContratante, telefoneContratante, nomeEmpresa]);

  useEffect(() => {
    setCnpj(cpfCliente);
  }, [cpfCliente]);

  useEffect(() => {
    if (representantes?.length > 0) {
      setCpfRepresentante(representantes[0]?.cpf);
      if (representantes[1]?.id) setCamposAdicionais(true);
      setRepresentante2(representantes[1]?.nomeCompleto);
      setEmail2(representantes[1]?.email);
      setTelefone2(representantes[1]?.telefone);
      setCpfRepresentante2(representantes[1]?.cpf);
    }
  }, [representantes]);

  const cleanForm = () => {
    setRepresentante2(null);
    setEmail2(null);
    setTelefone2(null);
    setCpfRepresentante2(null);
  };

  return (
    <Grid container spacing={2}>
      {podeEditarCpfCnpj && !statusVigenciaFechado && !mobileHabilitar && (
        <>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <CheckInputs setChecked={setChecked} checked={checked} />
          </Grid>
        </>
      )}
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputTexto
          type="text"
          id="nomeEmpresaContratante"
          name="nomeEmpresaContratante"
          label="Nome da empresa"
          defaultValue={nomeEmpresaContratante ?? ""}
          permiteValorBranco
          renderIconShowHide={false}
          ref={register({
            required:
              !nomeEmpresaContratante && enviarForm
                ? "O campo Nome empresa é obrigatório!"
                : false,
            maxLength: {
              value: 200,
              message: "Quantidade máxima de 200 caracteres!"
            }
          })}
          errors={errors}
          allowClear
          className={
            !editarInputs || statusVigenciaFechado ? classes.desabledInput : ""
          }
        />
      </Grid>
      {podeEditarCpfCnpj && !statusVigenciaFechado && mobileHabilitar && (
        <>
          <Grid item xs={12}>
            <CheckInputs setChecked={setChecked} checked={checked} />
          </Grid>
        </>
      )}
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputMascara
          type="text"
          id="cnpj"
          name="cnpj"
          label="CNPJ"
          mask="##.###.###/####-##"
          renderIconShowHide={false}
          defaultValue={cnpj ?? ""}
          ref={register({
            required: "Campo CNPJ é obrigatório!",
            maxLength: {
              value: 18,
              message: "Quantidade máxima de 18 caracteres!"
            },
            minLength: {
              value: 18,
              message: "Quantidade mínima de 18 caracteres!"
            },
            validate: (value) => validarCpfCnpj(value) || "CNPJ inválido!"
          })}
          errors={errors}
          className={
            !habiltarEditar || statusVigenciaFechado
              ? classes.desabledInput
              : ""
          }
        />
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputTexto
          type="text"
          id="nomeRepresentante"
          name="nomeRepresentante"
          label="Nome completo do representante legal"
          defaultValue={nomeRepresentante ?? ""}
          permiteValorBranco
          renderIconShowHide={false}
          ref={register({
            required: !nomeRepresentante
              ? "O campo Nome Completo do Representante legal é obrigatório!"
              : false,
            maxLength: {
              value: 200,
              message: "Quantidade máxima de 200 caracteres!"
            }
          })}
          errors={errors}
          allowClear
          className={statusVigenciaFechado ? classes.desabledInput : ""}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputTexto
          type="text"
          id="emailRepresentante"
          name="emailRepresentante"
          label="E-mail do representante legal"
          defaultValue={emailRepresentante ?? ""}
          permiteValorBranco
          renderIconShowHide={false}
          ref={register({
            required:
              enviarFormProposta || enviarForm
                ? "O campo E-mail do representante legal é obrigatório!"
                : false,
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Campo E-mail inválido!"
            },
            maxLength: {
              value: 100,
              message: "Quantidade máxima de 100 caracteres!"
            },
            validate: (value) =>
              !existeAcento(value) || "Campo E-mail inválido!"
          })}
          errors={errors}
          className={statusVigenciaFechado ? classes.desabledInput : ""}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputMascaraCpf
          type="text"
          id="cpfRepresentante"
          name="cpfRepresentante"
          label="CPF do representante legal"
          mask="###.###.###-##"
          renderIconShowHide={false}
          defaultValue={cpfRepresentante ?? ""}
          ref={register({
            required: enviarForm
              ? "O campo CPF Representante legal é obrigatório!"
              : false,
            maxLength: {
              value: 14,
              message: "Quantidade máxima de 14 caracteres!"
            },
            validate: (value) =>
              value ? validarCpfCnpj(value) || "CPF inválido!" : true
          })}
          errors={errors}
          disabled={statusVigenciaFechado}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <MaterialInputTelefone
          type="text"
          id="telefoneRepresentante"
          name="telefoneRepresentante"
          label="Telefone do representante legal"
          defaultValue={telefoneRepresentante ?? ""}
          permiteValorBranco
          renderIconShowHide={false}
          ref={register({
            required:
              enviarFormProposta || enviarForm
                ? "O campo Telefone do representante legal é obrigatório!"
                : false,
            minLength: {
              value: 18,
              message: "Quantidade mínima de 12 caracteres!"
            }
          })}
          errors={errors}
          className={statusVigenciaFechado ? classes.desabledInput : ""}
        />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider color="rgba(210, 210, 215, 1)" my={2} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box component="div">
          {camposAdicionais ? (
            <Box
              component="div"
              display="flex"
              alignContent="center"
              onClick={() => {
                setCamposAdicionais(false);
                clearErrors([
                  "dataNascimentoRepresentante2",
                  "sexoRepresentante2",
                  "estadoCivilRepresentante2"
                ]);
                setValue("dataNascimentoRepresentante2", "2");
                setValue("sexoRepresentante2", "2");
                setValue("estadoCivilRepresentante2", "2");
                cleanForm();
              }}
            >
              <Box>
                <Delete cursor="pointer" htmlColor="#332053" />
              </Box>
              <Box mt="2px" fontSize="14px" color="#4F4F4F">
                Remover 2° representante - opcional
              </Box>
            </Box>
          ) : (
            <Box
              component="div"
              display="flex"
              alignContent="center"
              onClick={() => {
                setCamposAdicionais(true);
                clearErrors([
                  "dataNascimentoRepresentante2",
                  "sexoRepresentante2",
                  "estadoCivilRepresentante2"
                ]);
                setValue("dataNascimentoRepresentante2", null);
                setValue("sexoRepresentante2", null);
                setValue("estadoCivilRepresentante2", null);
              }}
            >
              <Box>
                <Add cursor="pointer" htmlColor="#332053" />
              </Box>
              <Box mt="2px" fontSize="14px" color="#4F4F4F">
                Adicionar 2° representante - opcional
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      {camposAdicionais && (
        <>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <MaterialInputTexto
              type="text"
              id="nomeRepresentante2"
              name="nomeRepresentante2"
              label="Nome completo do 2° representante legal"
              defaultValue={nomeRepresentante2 ?? ""}
              permiteValorBranco
              renderIconShowHide={false}
              ref={register({
                required:
                  camposAdicionais && enviarForm
                    ? "O campo Nome Completo do 2° Representante legal é obrigatório!"
                    : false,
                maxLength: {
                  value: 200,
                  message: "Quantidade máxima de 200 caracteres!"
                }
              })}
              errors={errors}
              allowClear
              disabled={statusVigenciaFechado}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <MaterialInputTexto
              type="text"
              id="emailRepresentante2"
              name="emailRepresentante2"
              label="E-mail do 2° representante legal"
              defaultValue={emailRepresentante2 ?? ""}
              permiteValorBranco
              renderIconShowHide={false}
              ref={register({
                required:
                  camposAdicionais && enviarForm
                    ? "O campo E-mail do 2° representante legal é obrigatório!"
                    : false,
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Campo E-mail inválido!"
                },
                maxLength: {
                  value: 100,
                  message: "Quantidade máxima de 100 caracteres!"
                }
              })}
              errors={errors}
              disabled={statusVigenciaFechado}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <MaterialInputMascaraCpf
              type="text"
              id="cpfRepresentante2"
              name="cpfRepresentante2"
              label="CPF do 2° representante legal"
              mask="###.###.###-##"
              renderIconShowHide={false}
              defaultValue={cpfRepresentante2 ?? ""}
              ref={register({
                required:
                  camposAdicionais && enviarForm
                    ? "O campo CPF 2° Representante é obrigatório!"
                    : false,
                maxLength: {
                  value: 14,
                  message: "Quantidade máxima de 14 caracteres!"
                },
                validate: (value) =>
                  value ? validarCpfCnpj(value) || "CPF inválido!" : true
              })}
              errors={errors}
              disabled={statusVigenciaFechado}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <MaterialInputTelefone
              type="telefoneRepresentante2"
              id="telefoneRepresentante2"
              name="telefoneRepresentante2"
              label="Telefone do 2° representante legal"
              defaultValue={telefoneRepresentante2 ?? ""}
              permiteValorBranco
              renderIconShowHide={false}
              ref={register({
                required:
                  camposAdicionais && enviarForm
                    ? "O campo Telefone do 2° representante legal é obrigatório!"
                    : false,
                minLength: {
                  value: 18,
                  message: "Quantidade mínima de 12 caracteres!"
                }
              })}
              errors={errors}
              disabled={statusVigenciaFechado}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

InformacaoEmpresa.propTypes = {
  nomeContratante: PropTypes.string,
  emailContratante: PropTypes.string,
  telefoneContratante: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.func]),
  errors: PropTypes.string,
  clearErrors: PropTypes.oneOfType([PropTypes.func]),
  setValue: PropTypes.oneOfType([PropTypes.func]),
  cpfCliente: PropTypes.string,
  nomeEmpresa: PropTypes.string,
  enviarForm: PropTypes.bool,
  representantes: PropTypes.oneOfType([PropTypes.array]),
  editarInputs: PropTypes.bool,
  statusVigenciaFechado: PropTypes.bool,
  enviarFormProposta: PropTypes.bool
};
InformacaoEmpresa.defaultProps = {
  nomeContratante: "",
  emailContratante: "",
  telefoneContratante: "",
  register: () => {},
  errors: "",
  clearErrors: () => {},
  setValue: () => {},
  cpfCliente: "",
  nomeEmpresa: "",
  enviarForm: true,
  representantes: [],
  editarInputs: false,
  statusVigenciaFechado: false,
  enviarFormProposta: false
};

export default InformacaoEmpresa;
