import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import { Box, Card, Grid } from "@mui/material";

import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selecionarUnidade } from "../../../../global/redux/modulos/usuario/actions";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  BotaoRetornarListagem,
  Loader,
  SelectArredondado,
  SelectAutocomplete
} from "../../../../componentes";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import InputData from "../../../../componentes/inputTextoData";

import { useStyles } from "./style";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import Botao from "../../../../componentes/botao";
import UploadDropAreaMultiplo from "../../../../componentes/uploadMultiplo";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";

import {
  getTiposMedidores,
  getTiposTC,
  getUnidadeConsumidoraCliente,
  salvarMedidorGD,
  buscarPorId,
  getByCodigoId,
  uploadMultiplo,
  downloadArquivos,
  deleteArquivos
} from "../../../../servicos/medidorGDServico";
import {
  formatarCampo,
  validarCpfCnpj
} from "../../../../global/utils/formatCpfCnpj";
import { validacaoTrimObject } from "../../../../servicos/utils";
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import { obterOperacaoControlePorUnidadeId } from "../../../../servicos/operacaoControleServico";

const MedidorGD = () => {
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [carregandoSalvar, setCarregandoSalvar] = useState(false);
  const [cpfCnpjMask, setCpfCnpj] = useState(null);
  const [valueCpfCnpj, setValueCpfCnpj] = useState(null);
  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [carregandoTiposMedidores, setCarregandoTiposMedidores] = useState(
    false
  );
  const [carregandoTiposTC, setCarregandoTiposTC] = useState(false);
  const [unidadeConsumidoraId, setUnidadeConsumidoraId] = useState(null);
  const [
    listaUnidadesConsumidorasCombo,
    setListaUnidadesConsumidorasCombo
  ] = useState([]);
  const [codigoMedidor, setCodigoMedidor] = useState(null);
  const [tipoTCId, setTipoTC] = useState(null);
  const [listaTipoTC, setListaTipoTC] = useState([]);
  const [tipoMedidorId, setTipoMedidor] = useState(null);
  const [listaTipoMedidor, setListaTipoMedidor] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [carregandoUpload, setCarregandoUpload] = useState(0);
  // const [renderizaComponente, setRenderizaComponente] = useState(false);
  const [modalCodigoExistente, setModalCodigoExistente] = useState(false);
  const [codigoId, setCodigoId] = useState(null);
  const [cep, setCep] = useState(null);
  const [endereco, setEndereco] = useState(null);
  const [numero, setNumero] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [uf, setUf] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [carregandoDownload, setCarregandoDownload] = useState(false);
  const [dataDesagregacaoInicio, setDataDesagregacaoInicio] = useState(
    new Date("2090-12-30T00:00:00")
  );

  const [codigoPatrimonio, setCodigoPatrimonio] = useState(null);

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const [dadosMedidor, setDadosMedidor] = useState({});

  const permissaoEditarMedidorGD = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.MedidorGD,
    InterfaceDTO.PermissaoEditarMedidorGD
  );

  const enviarFormulario = async () => {
    try {
      setCarregandoSalvar(true);

      const model = {
        codigoMedidor,
        unidadeConsumidoraId,
        medidorGDTipoTCId: Number(tipoTCId),
        tipoMedidorId: Number(tipoMedidorId),
        codigoPatrimonio: codigoPatrimonio ?? ""
      };

      if (Number(tipoMedidorId) === 4)
        model.dataDesagregacaoInicio = moment(dataDesagregacaoInicio).format(
          "YYYY-MM-DDTHH:mm:ss"
        );

      const salvou = await salvarMedidorGD(id ?? 0, validacaoTrimObject(model));

      if (salvou?.status === 200 || salvou?.status === 204) {
        await obterOperacaoControlePorUnidadeId(unidadeConsumidoraId);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Medidor salvo com sucesso!"
          })
        );
        setTimeout(() => {
          history.push({
            pathname: RotasDTO.MedidorGD,
            state: { dadosMedidorGd: salvou?.data }
          });
        }, 1000);
        if (arquivos.length > 0) {
          try {
            setCarregandoUpload(0);
            const arquivosFiles = arquivos.filter(
              (item) => item.id === undefined
            );
            if (arquivosFiles.length > 0) {
              const uploadComSucesso = await uploadMultiplo(
                salvou?.data?.codigoMedidor,
                arquivosFiles
              );
              if (uploadComSucesso) setCarregandoUpload(100);
            }
          } catch (error) {
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem: error?.response?.data?.message
              })
            );
            setCarregandoUpload(0);
          }
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    } finally {
      setCarregandoSalvar(false);
    }
  };

  const aoEnviarFormulario = () => {
    enviarFormulario();
  };

  const handleCpfCnpj = (value) => {
    const mask = formatarCampo(value);
    setCpfCnpj(mask);
    setValueCpfCnpj(value);
  };

  const obterUnidadesConsumidoras = useCallback(async (cpfCnpj) => {
    try {
      setCarregandoUnidades(true);
      const lista = await getUnidadeConsumidoraCliente(cpfCnpj);
      if (lista?.data?.unidadeConsumidoraResponseList) {
        setListaUnidadesConsumidorasCombo(
          lista?.data?.unidadeConsumidoraResponseList
        );
      }

      if (lista?.data?.unidadeConsumidoraResponseList?.length === 1) {
        setUnidadeConsumidoraId(
          lista?.data?.unidadeConsumidoraResponseList[0]?.id
        );
      }

      if (lista?.data?.unidadeConsumidoraResponseList?.length > 1) {
        setCep(null);
        setEndereco(null);
        setNumero(null);
        setComplemento(null);
        setBairro(null);
        setCidade(null);
        setUf(null);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoUnidades(false);
    }
  }, []);

  useEffect(() => {
    if (valueCpfCnpj) {
      obterUnidadesConsumidoras(valueCpfCnpj.replace(/[^a-zA-Z0-9]/g, ""));
    }
  }, [valueCpfCnpj]);

  const onAddArquivoUpload = (lista) => {
    setArquivos(lista);
  };

  const onDeleteArquivoUpload = async (lista) => {
    if (lista?.id) {
      try {
        setCarregandoDownload(true);
        await deleteArquivos(codigoMedidor, lista?.name);
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              "Download indisponível. Salve a cotação para realizar o download do arquivo!"
          })
        );
      } finally {
        setCarregandoDownload(false);
      }
    }
    setArquivos((oldState) =>
      oldState.filter((item) => item.name !== lista.name)
    );
  };

  const onClickDownload = async (nomeArquivo) => {
    try {
      setCarregandoDownload(true);
      await downloadArquivos(codigoMedidor, nomeArquivo);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            "Download indisponível. Salve a cotação para realizar o download do arquivo!"
        })
      );
    } finally {
      setCarregandoDownload(false);
    }
  };

  const onPreviewChipClick = (nomeArquivo) => {
    onClickDownload(nomeArquivo);
  };

  const obterTiposMedidores = useCallback(async () => {
    try {
      setCarregandoTiposMedidores(true);
      const lista = await getTiposMedidores();
      if (lista?.data?.tiposMedidor)
        setListaTipoMedidor(lista?.data?.tiposMedidor);
    } catch (error) {
      console.info(error);
    } finally {
      setCarregandoTiposMedidores(false);
    }
  }, []);

  const obterTiposTC = useCallback(async () => {
    try {
      setCarregandoTiposTC(true);
      const lista = await getTiposTC();
      if (lista?.data?.medidorGDTipoTCLista)
        setListaTipoTC(lista?.data?.medidorGDTipoTCLista);
    } catch (error) {
      console.info(error);
    } finally {
      setCarregandoTiposTC(false);
    }
  }, []);

  useEffect(() => {
    obterTiposMedidores();
    obterTiposTC();
  }, []);

  const obterEnderecoUnidade = (idSelecionado) => {
    if (idSelecionado) {
      const enderecoUnidade = listaUnidadesConsumidorasCombo.filter(
        (item) => item.id === idSelecionado
      );
      setCep(enderecoUnidade[0]?.cep);
      setEndereco(enderecoUnidade[0]?.endereco);
      setNumero(enderecoUnidade[0]?.numero);
      setComplemento(enderecoUnidade[0]?.complemento);
      setBairro(enderecoUnidade[0]?.bairro);
      setCidade(enderecoUnidade[0]?.cidade);
      setUf(enderecoUnidade[0]?.uf);
    }
  };

  useEffect(() => {
    if (unidadeConsumidoraId) {
      obterEnderecoUnidade(unidadeConsumidoraId);
    }
  }, [unidadeConsumidoraId]);

  const handleArquivos = (arquivosBd) => {
    return arquivosBd.map((item) => ({
      ...item,
      name: item?.nomeArquivo
    }));
  };

  const obterMedidorPorId = useCallback(async (medidorId) => {
    try {
      setCarregandoUnidades(true);
      const medidor = await buscarPorId(medidorId);
      if (medidor?.status === 200 && medidor?.data) {
        if (!medidor.data) return;
        setCpfCnpj(formatarCampo(medidor?.data?.unidadeConsumidora?.cnpjCpf));
        setValueCpfCnpj(medidor?.data?.unidadeConsumidora?.cnpjCpf);
        setCodigoMedidor(medidor?.data?.codigoMedidor);
        setTipoTC(medidor?.data?.medidorGDTipoTC?.id);
        setTipoMedidor(medidor?.data?.tipoMedidor?.id);
        setCep(medidor?.data?.unidadeConsumidora?.cep);
        setEndereco(medidor?.data?.unidadeConsumidora?.endereco);
        setNumero(medidor?.data?.unidadeConsumidora?.numero);
        setComplemento(medidor?.data?.unidadeConsumidora?.complemento);
        setBairro(medidor?.data?.unidadeConsumidora?.bairro);
        setCidade(medidor?.data?.unidadeConsumidora?.cidade);
        setUf(medidor?.data?.unidadeConsumidora?.uf);
        setUnidadeConsumidoraId(medidor?.data?.unidadeConsumidoraId);
        setArquivos(handleArquivos(medidor?.data?.medidorGDArquivos) || []);
        if (medidor?.data?.dataDesagregacaoInicio)
          setDataDesagregacaoInicio(
            new Date(medidor?.data?.dataDesagregacaoInicio)
          );
        setCodigoPatrimonio(medidor?.data?.codigoPatrimonio);
        setDadosMedidor(medidor?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoUnidades(false);
    }
  }, []);

  useEffect(() => {
    if (id) obterMedidorPorId(id);
  }, [id]);

  useEffect(
    () => async () => {
      if (codigoMedidor && !id) {
        const codigo = await getByCodigoId(codigoMedidor);
        if (
          !id &&
          codigo?.status === 200 &&
          codigo?.data &&
          codigo?.data?.codigoMedidor
        ) {
          setCodigoId(codigo?.data?.id);
          setModalCodigoExistente(true);
        }
      }
    },
    [codigoMedidor]
  );

  const onConfirmarTelaEdicao = () => {
    history.push(`${RotasDTO.MedidorGD}/cadastro/${codigoId}`);
    setModalCodigoExistente(false);
  };

  return (
    <Loader loading={carregandoSalvar}>
      <ModalConfirmacao
        item={modalCodigoExistente}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="Código já cadastrada. Deseja ir para a tela de edição?"
        onCancelar={() => {
          setModalCodigoExistente(false);
          setCodigoMedidor(null);
        }}
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Box
            component={Grid}
            container
            spacing={2}
            p={2}
            className={classes.container}
          >
            <Grid item lg={6} xs={6} className="font-weight-bold">
              <Box component="div">
                {id
                  ? "Editar Medidor Geração Distribuída"
                  : "Nova Medidor Geração Distribuída"}
              </Box>
            </Grid>
            <BotaoRetornarListagem
              urlListagem={{
                pathname: RotasDTO.MedidorGD,
                state: { dadosMedidorGd: dadosMedidor }
              }}
              zerarCombo
            />
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="cpfCnpj"
                name="cpfCnpj"
                label="CPF|CNPJ"
                renderIconShowHide={false}
                defaultValue={cpfCnpjMask || ""}
                onBlur={(e) => handleCpfCnpj(e.target.value)}
                maxLength={18}
                ref={register({
                  required: "Campo cpf/cnpj é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 11,
                    message: "Quantidade mínima de 14 caracteres!"
                  },
                  validate: (value) => validarCpfCnpj(value)
                })}
                errors={errors}
                disabled={id}
                allowClear
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoUnidades}>
                <SelectAutocomplete
                  disableClearable
                  disableOpenOnFocus
                  id="unidadeConsumidoraId"
                  name="unidadeConsumidoraId"
                  label="Unidade Consumidora"
                  value={unidadeConsumidoraId}
                  options={listaUnidadesConsumidorasCombo}
                  getOptionLabel={(option) =>
                    (option?.nomeUnidade ?? option?.razaoSocial) || ""
                  }
                  onChange={(e, item) => {
                    setUnidadeConsumidoraId(item?.id ?? 0);
                    store.dispatch(selecionarUnidade(item?.id ?? 0));
                  }}
                  allowClear
                  ref={register(
                    { name: "unidadeConsumidoraId" },
                    {
                      required: !unidadeConsumidoraId
                        ? "Campo unidade consumidora é obrigatório!"
                        : false
                    }
                  )}
                  disabled={id}
                  error={errors}
                  valueName="nomeUnidade"
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="codigoMedidor"
                name="codigoMedidor"
                label="Código Medidor"
                renderIconShowHide={false}
                defaultValue={codigoMedidor?.toLowerCase() || ""}
                permiteValorBranco
                onBlur={(e) => setCodigoMedidor(e?.target?.value)}
                ref={register(
                  { name: "codigoMedidor" },
                  {
                    required: !codigoMedidor
                      ? "Campo código medidor é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                disabled={!permissaoEditarMedidorGD}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Loader loading={carregandoTiposTC}>
                <SelectArredondado
                  id="tipoTCId"
                  name="tipoTCId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaTipoTC}
                  label="Tipo TC"
                  value={tipoTCId}
                  onChange={(e) => {
                    setTipoTC(e.target.value);
                  }}
                  placeholder="Tipo TC"
                  allowClear={permissaoEditarMedidorGD}
                  ref={register(
                    { name: "tipoTCId" },
                    {
                      required: !tipoTCId
                        ? "Campo tipo TC é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                  disabled={!permissaoEditarMedidorGD}
                />
              </Loader>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="codigoPatrimonio"
                name="codigoPatrimonio"
                label="Patrimônio (opcional)"
                defaultValue={codigoPatrimonio ?? ""}
                permiteValorBranco
                renderIconShowHide={false}
                onBlur={(e) => {
                  setCodigoPatrimonio(e.target.value);
                }}
                ref={register({
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  }
                })}
                errors={errors}
                allowClear={permissaoEditarMedidorGD}
                disabled={!permissaoEditarMedidorGD}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Loader loading={carregandoTiposMedidores}>
                <SelectArredondado
                  id="tipoMedidorId"
                  name="tipoMedidorId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaTipoMedidor}
                  label="Tipo Medidor"
                  value={tipoMedidorId}
                  onChange={(e) => {
                    setTipoMedidor(e.target.value);
                  }}
                  placeholder="Tipo Medidor"
                  allowClear={permissaoEditarMedidorGD}
                  ref={register(
                    { name: "tipoMedidorId" },
                    {
                      required: !tipoMedidorId
                        ? "Campo tipo medidor é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                  disabled={!permissaoEditarMedidorGD}
                />
              </Loader>
            </Grid>

            {Number(tipoMedidorId) === 4 && (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Loader loading={carregandoTiposMedidores}>
                  <InputData
                    type="text"
                    id="dataDesagregacaoInicio"
                    name="dataDesagregacaoInicio"
                    label="Data Início Desagregação"
                    customValue={dataDesagregacaoInicio}
                    onChange={(data) => setDataDesagregacaoInicio(data)}
                    renderIconShowHide={false}
                    ref={register(
                      { name: "dataDesagregacaoInicio" },
                      {
                        required: !dataDesagregacaoInicio
                          ? "Campo data início desagregação é obrigatório!"
                          : false,
                        validate: () =>
                          moment(
                            dataDesagregacaoInicio,
                            "DD/MM/YYYY"
                          ).isValid() || "Data Início Desagregação inválida!"
                      }
                    )}
                    errors={errors}
                    className={classes}
                    InputProps={{ readOnly: false }}
                    variant={"inline"}
                    autoOk={true}
                    disabled={!permissaoEditarMedidorGD}
                  />
                </Loader>
              </Grid>
            )}

            <Grid item lg={12} xs={12} className="font-weight-bold">
              <Box component="div">Endereço</Box>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="cep"
                name="cep"
                label="Cep"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={cep || ""}
                disabled
                errors={errors}
              />
            </Grid>

            <Grid item lg={8} md={8} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="endereco"
                name="endereco"
                label="Endereço"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={endereco || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="numero"
                name="numero"
                label="Número"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={numero || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="complemento"
                name="complemento"
                label="Complemento"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={complemento || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="bairro"
                name="bairro"
                label="Bairro"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={bairro || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="cidade"
                name="cidade"
                label="Cidade"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={cidade || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="uf"
                name="uf"
                label="UF"
                renderIconShowHide={false}
                permiteValorBranco={!id}
                defaultValue={uf || ""}
                disabled
                errors={errors}
                allowClear
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <Loader loading={carregandoDownload}>
                <UploadDropAreaMultiplo
                  imageOrPdfValidate
                  onAdd={onAddArquivoUpload}
                  onDropRejected={onDeleteArquivoUpload}
                  progressValue={carregandoUpload}
                  acceptedFiles={["image/*", "application/pdf"]}
                  dropAreaText="Solte o arquivo para anexá-lo ou procurar"
                  filesLimit={10}
                  initialFiles={arquivos}
                  renderizaComponente
                  showPreviews
                  onPreviewChipClick={(nomeArquivo) =>
                    onPreviewChipClick(nomeArquivo)
                  }
                  disabled={!permissaoEditarMedidorGD}
                />
              </Loader>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={`${classes.containerSalvar}`}
            >
              <Botao
                type="submit"
                label="Salvar"
                className={classes.button}
                disabled={!permissaoEditarMedidorGD}
              />
            </Grid>
          </Box>
        </Card>
      </form>
    </Loader>
  );
};

export default MedidorGD;
