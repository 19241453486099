import RetornoEndpointDto from "../../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import {
  criarLeadUnidade,
  criarLeadPlanoSelecionado,
  criarTermoAdesao,
  atualizarSalesForce,
  criarLeadClientePFLuz,
  obterUnidadeConsumidoraSimulador,
  criarCotacaoGD,
  criarCotacaoGDPlanosGerado,
  atualizarCotacaoGD,
  atualizarCotacaoGDPlanosGerado,
  obterCotacaoGdId,
  obterCotacaoGd,
  cotacaoProposta
} from "../../../../../servicos/leadClienteLuzServico";

export default class SiteLuzLeadHelper {
  static async criarLeadClienteLuzPF(
    body,
    setError,
    handleLoading,
    id,
    dispatch
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarLeadClientePFLuz(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead sales force não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead cliente luz pf criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao criar lead cliente luz");
    }
  }

  static async criarLeadUnidade(body, setError, handleLoading, id, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarLeadUnidade(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Lead unidade não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Lead unidade criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao criar lead unidade luz");
    }
  }

  static async criarLeadPlanoSelecionado(
    body,
    setError,
    handleLoading,
    id,
    dispatch
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarLeadPlanoSelecionado(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead plano selecionad não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead plano selecionado criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Erro ao criar lead plano selecionado luz"
      );
    }
  }

  static async criarTermoAdesao(
    params,
    setError,
    handleLoading,
    dispatch,
    setStatusLoading
  ) {
    try {
      dispatch(handleLoading(true));
      setStatusLoading("Aguarde! Processando Contrato.");
      const resultado = await criarTermoAdesao(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead plano selecionad não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead plano selecionado criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Erro ao criar lead plano selecionado luz"
      );
    }
  }

  static async atualizarSalesForce(
    body,
    handleLoading,
    setError,
    dispatch,
    setStatusLoading,
    enviarForm
  ) {
    try {
      dispatch(handleLoading(true));
      setStatusLoading(
        enviarForm === false ? "Dados Enviados." : "Contrato Enviado."
      );
      const resultado = await atualizarSalesForce(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Lead sales forces atualizado não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Lead sales forces atualizado criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao atualizar lead sales forces luz");
    } finally {
      dispatch(handleLoading(false));
    }
  }

  static async obterUnidadeConsumidoraSimulador(
    params,
    setError,
    handleLoading,
    dispatch
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await obterUnidadeConsumidoraSimulador(params);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Unidade consumidora não encontrada!");

      return RetornoEndpointDto.Sucesso(
        "Unidade consumidora encontrada!",
        resultado.data
      );
    } catch (error) {
      if (error.response?.status === 409) {
        return RetornoEndpointDto.Erro("unidadeExists");
      }
      setError(true);
      dispatch(handleLoading(false));
      return RetornoEndpointDto.Erro("Erro ao buscar unidade consumidora!");
    }
  }

  static async criarCotacaoGD(
    body,
    setError,
    handleLoading,
    id,
    dispatch,
    setStatusLoading
  ) {
    try {
      setStatusLoading("Aguarde! Processando Cliente.");
      dispatch(handleLoading(true));

      const resultado = await criarCotacaoGD(body, id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação GD não encontrados!");

      return RetornoEndpointDto.Sucesso(
        "Cotação GD criado com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao criar cotação GD");
    }
  }

  static async criarCotacaoGDPlanosGerado(
    body,
    setError,
    handleLoading,
    dispatch
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await criarCotacaoGDPlanosGerado(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Cotação GD planos selecionados não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Cotação GD planos selecionados criado com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Erro ao criar cotacão GD planos selecionados!"
      );
    }
  }

  static async atualizarCotacaoGD(id, body, setError, handleLoading, dispatch) {
    try {
      dispatch(handleLoading(true));
      const resultado = await atualizarCotacaoGD(id, body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Cotação GD atualizada não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Cotação GD atualizada com sucesso",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro("Erro ao atualizar cotação GD");
    }
  }

  static async atualizarCotacaoGDPlanosGerado(
    body,
    setError,
    handleLoading,
    dispatch
  ) {
    try {
      dispatch(handleLoading(true));
      const resultado = await atualizarCotacaoGDPlanosGerado(body);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Cotação GD planos selecionados não encontrados!"
        );

      return RetornoEndpointDto.Sucesso(
        "Cotação GD planos selecionados atualizada com sucesso!",
        resultado.data
      );
    } catch (error) {
      setError(true);
      return RetornoEndpointDto.Erro(
        "Erro ao atualizar cotacão GD planos selecionados!"
      );
    }
  }

  static async obterCotacaoGdId(id) {
    try {
      const resultado = await obterCotacaoGdId(id);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("PLanos não encontrados!");
      return RetornoEndpointDto.Sucesso(
        "PLanos obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    }
  }

  static async obterCotacaoGd(model) {
    try {
      const resultado = await obterCotacaoGd(model);
      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Cotação gd não encontrada!");
      return RetornoEndpointDto.Sucesso(
        "Cotação gd obtido com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Planos não encontrados!");
    }
  }

  static async cotacaoProposta(
    body,
    handleLoading,
    dispatch,
    setStatusLoading
  ) {
    try {
      setStatusLoading("...Baixando proposta!");
      dispatch(handleLoading(true));
      const { data, status } = await cotacaoProposta(body);

      if (status === 204)
        return RetornoEndpointDto.Erro("Proposta gd não pode ser gerado!");

      return RetornoEndpointDto.Sucesso(
        "Dados proposta obtidos com sucesso!",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro obter os dados da proposta!");
    } finally {
      dispatch(handleLoading(false));
      setStatusLoading("");
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
