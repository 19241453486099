import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Card, Grid, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import { extendMoment } from "moment-range";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import MaterialInputTextoMoeda from "../../../../componentes/inputTexto/materialInputMoeda";
import BotaoForm from "../../../../componentes/botaoForm";
import { formatarBrasileira } from "../../../../servicos/utils";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import Botao from "../../../../componentes/botao";
import InputData from "../../../../componentes/inputTextoData";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import SelectArredondado from "../../../../componentes/selectArredondado";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";

import { listarClientesCombo } from "../../../../servicos/clientesServico";
import { listarEmpresasGrupoDeltaCombo } from "../../../../servicos/empresaServico";
import { listarUnidadesSemPaginacao } from "../../../../servicos/unidadesServico";
import { listarStatus } from "../../../../servicos/statusServico";
import {
  salvarBoletaGD,
  buscarPorId,
  getPrazoPlano,
  getUnidadeConsumidoraId
} from "../../../../servicos/boletasGDServico";
import { listarPagamento } from "../../../../servicos/pagamentoServico";
import {
  obterOperacaoControlePorUnidadeId,
  atualizarPassoOnboarding,
  atualizarOperacaoControle
} from "../../../../servicos/operacaoControleServico";

import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { store } from "../../../../global/redux";
import {
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

import Loader from "../../../../componentes/loader";

import { useStyles } from "./style";

import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { confirmacao } from "../../../../global/constantes";
import CadastradoBoletaGdHelper from "./helper";
import MaterialInputNumberFormat from "../../../../componentes/inputTexto/materialInputNumberFormat";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";

const BoletaCadastroGD = () => {
  const moment = extendMoment(window.moment);
  const usuario = useSelector((state) => state.usuario);
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [carregandoSalvar, setCarregandoSalvar] = useState(false);
  const [confirmarId, setConfirmarId] = useState(null);
  const [dataBoletaCreate, setDataBoleta] = useState(null);
  const [dataFornecimento, setDataFornecimento] = useState(null);
  const [motivoCancelamento, setMotivoCancelamento] = useState(null);
  const [dataCancelamento, setDataCancelamento] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [empresaId, setEmpresaId] = useState(null);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(false);
  const [clienteId, setClienteId] = useState(null);
  const [listaCliente, setListaCliente] = useState([]);
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [
    listaUnidadesConsumidorasCombo,
    setListaUnidadesConsumidorasCombo
  ] = useState([]);
  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [unidadeConsumidoraId, setUnidadeConsumidoraId] = useState(null);
  const [desconto, setDesconto] = useState();
  const [statusLista, setStatusLista] = useState([]);
  const [statusId, setStatusId] = useState();
  const [carregandoStatus, setCarregandoStatus] = useState(false);
  const [carregandoPagamento, setCarregandoPagamento] = useState(false);
  const [pagamentoLista, setPagamentoLista] = useState([]);
  const [pagamentoId, setPagamentoId] = useState();
  const [disabledStatus, setDisableStatus] = useState();
  const [mostrarInputs, setMostrarInputs] = useState(false);
  const [validador, setValidador] = useState(false);
  const [carregandoPrazoPlano, setCarregandoPrazoPlano] = useState(false);
  const [prazoLista, setPrazoLista] = useState([]);
  const [prazoId, setPrazoId] = useState(null);
  const [modalUnidadeConsumidora, setModalUnidadeconsumidora] = useState(false);
  const [dadosBoleta, setDadosBoleta] = useState({});
  const [taxaIluminacao, setTaxaIluminacao] = useState(null);
  const [consumo, setConsumo] = useState(null);
  const { menu } = usuario;
  const rotas = useSelector((state) => state.rotas);
  const [numeroContrato, setNumeroContrato] = useState(null);
  const [modalReativarBoletaGd, setModalReativarBoletaGd] = useState(false);
  const [valorConta, setValorConta] = useState(null);

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  const podeEditarBoletaGd = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.BoletasGD,
    InterfaceDTO.PodeEditarBoletaGd
  );

  const permissaoEditar = useMemo(() => {
    return !podeEditarBoletaGd && id;
  }, [podeEditarBoletaGd, id]);

  const permissaoReativar = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.BoletasGD,
    InterfaceDTO.PermissaoReativarBoletaGD
  );

  const permisaoReativarBoletaGd = useMemo(() => {
    return permissaoReativar && id && (statusId === 3 || statusId === 4);
  }, [permissaoReativar, id, statusId]);

  useEffect(() => {
    if (confirmarId) {
      setValidador(false);
    }
  }, [confirmarId]);

  useEffect(() => {
    if (statusId === "3" || statusId === "4") {
      setMostrarInputs(true);
      setDataCancelamento(moment().toDate());
    } else if (statusId === "1" || statusId === "2") {
      setMostrarInputs(false);
      setDataCancelamento(null);
      setMotivoCancelamento(null);
      setConfirmarId(null);
    }
  }, [statusId]);

  const obterBoletaPorId = async (boletaId) => {
    try {
      const boleta = await buscarPorId(boletaId);
      if (boleta?.status === 200 && boleta?.data) {
        if (!boleta.data) return;

        const statusDisabilitado =
          boleta?.data?.statusId === 3 || boleta?.data?.statusId === 4;
        if (statusDisabilitado) {
          setMostrarInputs(true);
        }
        setDisableStatus(statusDisabilitado);

        let confirm;
        if (boleta?.data?.cancelamentoImediato === "S") {
          confirm = "1";
        } else if (boleta?.data?.cancelamentoImediato === "N") {
          confirm = "2";
        }

        setEmpresaId(boleta?.data?.empresaGrupoDeltaId);
        setClienteId(boleta?.data?.clienteId);
        setUnidadeConsumidoraId(boleta?.data?.unidadeConsumidoraId);
        setStatusId(boleta?.data?.statusId);
        setDataBoleta(boleta?.data?.dataInicio);
        setDataFornecimento(boleta?.data?.fornecimento);
        setDesconto(boleta?.data?.desconto);
        setQuantidade(boleta?.data?.qtdDiasCancelamento);
        setDataCancelamento(boleta?.data?.dataSolicitacaoCancelamento);
        setMotivoCancelamento(boleta?.data?.motivoCancelamento);
        setConfirmarId(confirm);
        setPagamentoId(boleta?.data?.formaPagamentoId);
        setPrazoId(boleta?.data?.planoPrazoId);
        setDadosBoleta(boleta?.data);
        setNumeroContrato(boleta?.data?.numeroContrato);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (id) obterBoletaPorId(id);
  }, [id]);

  const obterEmpresa = useCallback(async () => {
    try {
      setCarregandoEmpresa(true);
      const lista = await listarEmpresasGrupoDeltaCombo(usuario?.token);
      if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
        setListaEmpresa(lista?.data.empresasGrupoDelta ?? []);
      }
      setCarregandoEmpresa(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresa(false);
    }
  }, []);

  useEffect(() => {
    if (!id) setDataBoleta(moment().toDate());
    obterEmpresa();
  }, []);

  useEffect(() => {
    if (!id && usuario?.empresaSelecionada)
      setEmpresaId(Number(usuario?.empresaSelecionada));
    if (!id && usuario?.clienteSelecionado)
      setClienteId(Number(usuario?.clienteSelecionado));
  }, [usuario]);

  const obterCliente = useCallback(async (empresa) => {
    try {
      setCarregandoCliente(true);
      if (empresa) {
        const lista = await listarClientesCombo(empresa, usuario?.token);
        if (lista?.status === 200 && lista?.data?.clientes) {
          setListaCliente(lista?.data?.clientes);
        } else {
          setListaCliente([]);
        }
      } else {
        setListaCliente([]);
      }
      setCarregandoCliente(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCliente(false);
    }
  }, []);

  useEffect(() => {
    obterCliente(empresaId);
    if (!empresaId) {
      setClienteId(null);
      setUnidadeConsumidoraId(null);
      store.dispatch(selecionarCliente(null));
      store.dispatch(selecionarUnidade(null));
    }
  }, [empresaId]);

  const obterUnidadesConsumidoras = useCallback(async (empresa, cliente) => {
    try {
      setCarregandoUnidades(true);
      const lista = await listarUnidadesSemPaginacao(empresa, cliente);
      if (lista?.data?.unidadeConsumidoraResponseList)
        setListaUnidadesConsumidorasCombo(
          lista?.data?.unidadeConsumidoraResponseList
        );
    } catch (error) {
      console.info(error);
    } finally {
      setCarregandoUnidades(false);
    }
  }, []);

  useEffect(() => {
    if (empresaId && clienteId) obterUnidadesConsumidoras(empresaId, clienteId);
    if (!empresaId) {
      setClienteId(null);
      setUnidadeConsumidoraId(null);
    }
    if (!clienteId) {
      setUnidadeConsumidoraId(null);
    }
  }, [empresaId, clienteId]);

  useEffect(() => {
    setTimeout(() => {
      const unidade = listaUnidadesConsumidorasCombo.find(
        (item) => item.id === unidadeConsumidoraId
      );

      const cosip = unidade?.cosip !== 0 ? unidade?.cosip?.toFixed(2) : "0";
      const consumo =
        unidade?.consumoHistorico !== 0
          ? unidade?.consumoHistorico?.toFixed(2)
          : "0";
      const valorConta =
        unidade?.contaSemGD !== 0 ? unidade?.contaSemGD?.toFixed(2) : "0";

      setTaxaIluminacao(formatarBrasileira(cosip));
      setConsumo(consumo);
      setValorConta(formatarBrasileira(valorConta));

      if (unidade?.consumoHistorico === null) {
        setConsumo(null);
      }
    }, 500);
  }, [unidadeConsumidoraId, listaUnidadesConsumidorasCombo]);

  // desconto
  // 15.000,00 -> 150000.00
  // 0,750 -> 0.75
  const formatarValorSalvar = (valor) => {
    return valor
      ? parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.trim()
        )
      : valor;
  };

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await listarStatus();
      if (lista?.status === 200 && lista?.data?.status) {
        setStatusLista(lista?.data.status ?? []);
      }

      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  useEffect(() => {
    obterStatus();
  }, []);

  const obterPagamento = useCallback(async () => {
    try {
      setCarregandoPagamento(true);
      const lista = await listarPagamento();
      if (lista.status === 200 && lista?.data) {
        setPagamentoLista(lista?.data ?? []);
      }
      setCarregandoPagamento(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoPagamento(false);
    }
  }, []);

  useEffect(() => {
    obterPagamento();
  }, []);

  const handleTooltipDescricao = (item) => {
    switch (item.sigla) {
      case "LA":
        return `${item.descricao} - Boleta Criada`;
      case "AP":
        return `${item.descricao} - Boleta em fornecimento`;
      case "CA":
        return `${item.descricao} - Cancelamento pelo cliente`;
      case "RJ":
        return `${item.descricao} - Cancelamento pela LUZ`;
      case "UP":
        return `${item.descricao} - Upgrade pela LUZ`;
      default:
        return item.descricao;
    }
  };

  const handleTooltip = () => {
    return statusLista?.map((item) => {
      return <div key={item.id}>{handleTooltipDescricao(item)}</div>;
    });
  };

  const handleConfirmacao = () => {
    return (
      <>
        <div>
          <b>Sim: </b>
          Não respeitar os dias para cancelamento, cancelamento imidiato.
        </div>
        <div>
          <b>Não: </b>
          Cancelamento ocorrerá somente após os dias previsto nos boletos.
        </div>
      </>
    );
  };

  const resetarFormulario = () => {
    setEmpresaId(null);
    setClienteId(null);
    setUnidadeConsumidoraId(null);
    setStatusId("");
    setDataBoleta(null);
    setDataFornecimento(null);
    setDesconto(12);
    setQuantidade(3);
    setDataCancelamento(null);
    setMotivoCancelamento("");
    setConfirmarId(null);
  };

  const formatarSalvar = (valor) => {
    if (!valor) return "";
    return valor ? parseFloat(String(valor).replace(",", ".")) : valor;
  };

  const enviarFormulario = async () => {
    let sigla;
    if (confirmarId === "1") {
      sigla = "S";
    } else if (confirmarId === "2") {
      sigla = "N";
    }

    if (motivoCancelamento === null || confirmarId === undefined) {
      setValidador(true);
    }

    try {
      setCarregandoSalvar(true);

      let unidadeConsumidaoraInvalida = false;
      let novoPassoOnboarding = null;
      if (Number(statusId) === 2) {
        await getUnidadeConsumidoraId(unidadeConsumidoraId)
          .then((response) => {
            if (response.status === 204) {
              unidadeConsumidaoraInvalida = true;
            }
          })
          .catch((error) => {
            if (error.response.status !== 409) {
              throw new Error(error);
            }
          });
      }

      if (unidadeConsumidaoraInvalida) {
        setModalUnidadeconsumidora(true);
        return;
      }

      const model = {
        EmpresaGrupoDeltaID: Number(empresaId),
        ClienteID: Number(clienteId),
        UnidadeConsumidoraID: Number(unidadeConsumidoraId),
        DataCriacaoBoleta: moment(dataBoletaCreate).format(
          "yyyy-MM-DD HH:mm:ss"
        ),
        DataInicioFornecimento: moment(dataFornecimento).format(
          "yyyy-MM-DD HH:mm:ss"
        ),
        FormaPagamentoID: Number(pagamentoId),
        QtdDiasCancelamento: Number(quantidade),
        MotivoCancelamento: motivoCancelamento,
        CancelamentoImediato: sigla || null,
        DataSolicitacaoCancelamento: !dataCancelamento
          ? null
          : moment(dataCancelamento).format("yyyy-MM-DD HH:mm:ss"),
        Desconto: desconto,
        StatusId: Number(statusId),
        PrazoPlanoId: Number(prazoId),
        Consumo: consumo?.toString() !== "0" ? formatarSalvar(consumo) : 0,
        Cosip:
          taxaIluminacao?.toString() !== "0" ? formatarTaxa(taxaIluminacao) : 0,
        numeroContrato: numeroContrato || null,
        contaSemGD:
          valorConta?.toString() !== "0" ? formatarTaxa(valorConta) : 0
      };

      let salvou = null;
      const nomeCliente = listaCliente?.find((item) => item?.id === clienteId)
        ?.nomeFantasia;
      if (model.StatusId === 3 || model.StatusId === 4) {
        const operacaoControle = await obterOperacaoControlePorUnidadeId(
          unidadeConsumidoraId
        );
        if (operacaoControle != null) {
          const atualizacao = await atualizarOperacaoControle(
            operacaoControle?.data?.id,
            {
              motivoCancelamentoCliente: motivoCancelamento,
              observacao: operacaoControle?.data?.observacao,
              documentoProblema: false,
              listaControleDocumento:
                operacaoControle?.data?.listaControleDocumento
            }
          );
          if (atualizacao != null) {
            novoPassoOnboarding = await atualizarPassoOnboarding(
              operacaoControle?.data.id,
              14
            );
          }
        }
        if (novoPassoOnboarding != null) {
          salvou = await salvarBoletaGD(id ?? 0, model);

          if (salvou?.status === 200 || salvou?.status === 204) {
            resetarFormulario();
            store.dispatch(
              alertaExibir({
                tipo: "success",
                mensagem: "Boleta salva com sucesso!"
              })
            );
            history.push(
              `${RotasDTO.AcompanhamentoCliente}/${unidadeConsumidoraId}/${nomeCliente}`
            );
          }
        }
      } else {
        salvou = await salvarBoletaGD(id ?? 0, model);
      }

      if (salvou?.status === 200 || salvou?.status === 204) {
        resetarFormulario();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Boleta salva com sucesso!"
          })
        );

        setTimeout(() => {
          history.push({
            pathname: RotasDTO.BoletasGD,
            state: { dadosBoletaGd: { ...model, id } }
          });
        }, 1000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    } finally {
      setCarregandoSalvar(false);
    }
  };

  const [modalUnidadeExistente, setModalUnidadeExistente] = useState(false);
  const [unidadeId, setUnidadeId] = useState(null);

  const getByUnidadeId = async () => {
    if (unidadeConsumidoraId && !id) {
      const {
        data,
        sucesso,
        mensagem
      } = await CadastradoBoletaGdHelper.getByUnidadeId(
        unidadeConsumidoraId,
        setCarregandoSalvar
      );

      if (!sucesso) {
        CadastradoBoletaGdHelper.exibirErro(mensagem);
        setCarregandoSalvar(false);
        setModalUnidadeExistente(false);
        return;
      }

      if (mensagem !== "204") {
        setUnidadeId(data?.id);
        setModalUnidadeExistente(true);
        return;
      }
    }

    enviarFormulario();
  };

  const aoEnviarFormulario = () => {
    getByUnidadeId();
  };

  const onConfirmarTelaEdicao = () => {
    history.push(`${RotasDTO.BoletasGD}/cadastro/${unidadeId}`);
    setModalUnidadeExistente(false);
  };

  const obterPrazoPlano = useCallback(async () => {
    try {
      setCarregandoPrazoPlano(true);
      const lista = await getPrazoPlano();
      if (lista.status === 200 && lista?.data) {
        setPrazoLista(lista?.data ?? []);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoPrazoPlano(false);
    }
  }, []);

  useEffect(() => {
    obterPrazoPlano();
  }, []);

  const formatarTaxa = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";
    return valor
      ? parseFloat(
          String(valor)
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace(" ", "")
            .replaceAll(".", "")
            .replace(",", ".")
        )
      : valor;
  };

  const statusCadastro = (statusLista) => {
    const status = !id
      ? statusLista?.filter((item) => item?.sigla !== "AP")
      : statusLista;
    return status || [];
  };

  useEffect(() => {
    if (!unidadeConsumidoraId || !clienteId || !empresaId) {
      setConsumo(null);
      setTaxaIluminacao(null);
      setValorConta(null);
    }
  }, [unidadeConsumidoraId, clienteId, empresaId]);

  const formatarConsumo = (valor) => {
    if (!valor) return "";
    return valor === "0"
      ? valor
      : parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.trim()
        );
  };

  const reativarBoletaGd = async () => {
    setModalReativarBoletaGd(false);
    if (!id) return "";

    const model = {
      boletaGDId: Number(id) || 0,
      unidadeConsumidoraId: Number(unidadeConsumidoraId) || 0,
      clienteId: Number(clienteId) || 0,
      usuarioLogadoId: Number(usuario?.usuario?.id) || 0
    };

    const {
      sucesso,
      mensagem
    } = await CadastradoBoletaGdHelper.reativarBoletaGd(
      model,
      setCarregandoSalvar
    );

    if (!sucesso) {
      CadastradoBoletaGdHelper.exibirErro(mensagem);
      setCarregandoSalvar(false);
      setModalReativarBoletaGd(false);
      return;
    }

    setTimeout(() => {
      history.push({
        pathname: RotasDTO.BoletasGD,
        state: { dadosBoletaGd: dadosBoleta }
      });
    }, 1000);
  };

  const handleMensagemModal = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" fontSize="24px">
        {mensagem}
      </Box>
    );
  };

  return (
    <Loader loading={carregandoSalvar}>
      <ModalConfirmacao
        item={modalUnidadeConsumidora}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="CNPJ da associação não cadastrada na  Unidade Consumidora. Não foi possível atualizar a boleta."
        onCancelar={() => {
          setModalUnidadeconsumidora(false);
        }}
        esconderBotaoConfirmar
      />

      <ModalConfirmacao
        item={modalUnidadeExistente}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="Deseja ir para a tela de edição?"
        onCancelar={() => {
          setModalUnidadeExistente(false);
        }}
      />

      <ModalConfirmacao
        item={modalReativarBoletaGd}
        onConfirmar={() => reativarBoletaGd()}
        mensagem={handleMensagemModal("Deseja reativar a boleta?")}
        onCancelar={() => {
          setModalReativarBoletaGd(false);
        }}
      />

      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item lg={6} xs={6} className="font-weight-bold">
              {id
                ? "Editar  Boleta Geração distribuída"
                : "Nova  Boleta Geração distribuída"}
            </Grid>

            <BotaoRetornarListagem
              urlListagem={{
                pathname: RotasDTO.BoletasGD,
                state: { dadosBoletaGd: dadosBoleta }
              }}
              zerarCombo
            />

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoEmpresa}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="empresaId"
                  name="empresaId"
                  label="Empresa"
                  value={empresaId}
                  options={listaEmpresa}
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setEmpresaId(item?.id ?? 0);
                    store.dispatch(selecionarEmpresa(item?.id ?? 0));
                  }}
                  allowClear
                  ref={register(
                    { name: "empresaId" },
                    {
                      required: !empresaId
                        ? "Campo empresa é obrigatório!"
                        : false
                    }
                  )}
                  error={errors}
                  disabled={id}
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoCliente}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="clienteId"
                  name="clienteId"
                  label="Cliente"
                  value={clienteId}
                  options={listaCliente.filter(
                    (cliente) => cliente.nomeFantasia
                  )}
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setClienteId(item?.id ?? 0);
                    store.dispatch(selecionarCliente(item?.id ?? 0));
                  }}
                  allowClear
                  ref={register(
                    { name: "clienteId" },
                    {
                      required: !clienteId
                        ? "Campo Cliente é obrigatório!"
                        : false
                    }
                  )}
                  error={errors}
                  disabled={id}
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoUnidades}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="unidadeConsumidoraId"
                  name="unidadeConsumidoraId"
                  label="Unidade Consumidora"
                  value={unidadeConsumidoraId}
                  options={listaUnidadesConsumidorasCombo}
                  getOptionLabel={(option) =>
                    (option?.nomeUnidade ?? option?.razaoSocial) || ""
                  }
                  onChange={(e, item) => {
                    setUnidadeConsumidoraId(item?.id ?? 0);
                    store.dispatch(selecionarUnidade(item?.id ?? 0));
                  }}
                  allowClear
                  disabled={!clienteId || id}
                  ref={register(
                    { name: "unidadeConsumidoraId" },
                    {
                      required: !unidadeConsumidoraId
                        ? "Campo unidade consumidora é obrigatório!"
                        : false
                    }
                  )}
                  error={errors}
                  valueName="nomeUnidade"
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputData
                type="text"
                id="dataBoleta"
                name="dataBoleta"
                label="Data de criação da boleta"
                customValue={dataBoletaCreate}
                maxDate={moment().startOf("day").toDate()}
                onChange={(data) => setDataBoleta(data)}
                ref={register(
                  { name: "dataBoleta" },
                  {
                    required: !dataBoletaCreate
                      ? "Campo Data da boleta é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                className={classes}
                disabled={id}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputData
                type="text"
                id="dataFornecimento"
                name="dataFornecimento"
                label="Data de inicio de fornecimento"
                customValue={dataFornecimento}
                onChange={(data) => setDataFornecimento(data)}
                ref={register(
                  { name: "dataFornecimento" },
                  {
                    required: !dataFornecimento
                      ? "Campo Data da boleta é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                className={classes}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputMascara
                type="text"
                id="desconto"
                name="desconto"
                sufixo=" %"
                label="Desconto (%)"
                defaultValue={desconto}
                renderIconShowHide={false}
                onBlur={(e) => setDesconto(formatarValorSalvar(e.target.value))}
                ref={register(
                  { name: "desconto" },
                  {
                    required: !desconto
                      ? "Campo de Desconto é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoStatus}>
                <Box display="flex" justifyContent="end" marginRight={4}>
                  <Tooltip title={handleTooltip()}>
                    <InfoOutlined
                      className={classes.info}
                      fontSize="small"
                      ml={3}
                    />
                  </Tooltip>
                </Box>
                <SelectArredondado
                  id="statusId"
                  name="statusId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={statusCadastro(statusLista)}
                  label="Status"
                  value={statusId}
                  onChange={(status) => {
                    setStatusId(status.target.value);
                  }}
                  placeholder="Status"
                  allowClear={!id && !mostrarInputs}
                  ref={register(
                    { name: "statusId" },
                    {
                      required: !statusId
                        ? "Campo Status é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                  disabled={disabledStatus}
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoPagamento}>
                <SelectArredondado
                  id="pagamentoId"
                  name="pagamentoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={pagamentoLista}
                  label="Forma de pagamento"
                  value={pagamentoId}
                  onChange={(status) => {
                    setPagamentoId(status.target.value);
                  }}
                  placeholder="Forma de pagamento"
                  allowClear
                  ref={register(
                    { name: "pagamentoId" },
                    {
                      required: !pagamentoId
                        ? "Campo Pagamento é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputMascara
                type="text"
                id="quantidade"
                name="quantidade"
                label="Quantidade de dias para o cancelamento"
                sufixo=" dias"
                renderIconShowHide={false}
                defaultValue={quantidade}
                onBlur={(e) =>
                  setQuantidade(formatarValorSalvar(e.target.value))
                }
                ref={register(
                  { name: "quantidade" },
                  {
                    required:
                      !quantidade || quantidade <= 0
                        ? "Campo quantidade é obrigatório!"
                        : false
                  }
                )}
                errors={errors}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoPrazoPlano}>
                <SelectArredondado
                  id="prazoId"
                  name="prazoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={prazoLista}
                  label="Prazo do plano"
                  value={prazoId}
                  onChange={(e) => {
                    setPrazoId(e.target.value);
                  }}
                  placeholder="Prazo do plano"
                  allowClear={!id && !mostrarInputs}
                  ref={register(
                    { name: "prazoId" },
                    {
                      required: !prazoId
                        ? "Campo prazo do plano é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                  // disabled={disabledStatus}
                />
              </Loader>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputNumberFormat
                type="text"
                id="consumo"
                name="consumo"
                sufixo=" kW"
                label="Consumo Contratado (KWh)"
                defaultValue={consumo || ""}
                renderIconShowHide={false}
                onInputChange={(valor) => setConsumo(formatarConsumo(valor))}
                ref={register(
                  { name: "consumo" },
                  {
                    required:
                      !consumo && consumo !== 0
                        ? "Campo Consumo Contratado é obrigatório!"
                        : false
                  }
                )}
                errors={errors}
                disabled={permissaoEditar}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTextoMoeda
                type="text"
                id="taxaIluminacao"
                name="taxaIluminacao"
                label="Iluminação Pública"
                defaultValue={taxaIluminacao || ""}
                renderIconShowHide={false}
                onInput={(e) => {
                  setTaxaIluminacao(formatarBrasileira(e.target.value));
                }}
                ref={register(
                  { name: "taxaIluminacao" },
                  {
                    required: !taxaIluminacao
                      ? "Campo Iluminação Pública é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                disabled={permissaoEditar}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="numeroContrato"
                name="numeroContrato"
                label="Número Contrato"
                renderIconShowHide={false}
                defaultValue={numeroContrato || ""}
                onBlur={(e) => setNumeroContrato(e.target.value)}
                ref={register(
                  { name: "numeroContrato" },
                  {
                    required: !numeroContrato
                      ? "Campo número contrato é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                disabled={permissaoEditar}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTextoMoeda
                type="text"
                id="valorConta"
                name="valorConta"
                label="Valor Conta"
                defaultValue={valorConta || ""}
                renderIconShowHide={false}
                onInput={(e) => {
                  setValorConta(formatarBrasileira(e.target.value));
                }}
                ref={register(
                  { name: "valorConta" },
                  {
                    required: !valorConta
                      ? "Campo Valor Conta é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
                disabled={permissaoEditar}
              />
            </Grid>

            <Grid
              component={Box}
              item
              xl={4}
              lg={4}
              md={4}
              sm={4}
              display={{
                xl: !mostrarInputs ? "block" : "none",
                lg: !mostrarInputs ? "block" : "none",
                md: !mostrarInputs ? "block" : "none",
                sm: "none",
                xs: "none"
              }}
            />

            {mostrarInputs && (
              <>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputData
                    type="text"
                    id="dataCancelamento"
                    name="dataCancelamento"
                    label="Data do Cancelamento"
                    customValue={dataCancelamento}
                    onChange={(data) => setDataCancelamento(data)}
                    ref={register(
                      { name: "dataCancelamento" },
                      {
                        required: !dataCancelamento
                          ? "Campo data do cancelamento é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                    className={classes}
                    allowClear={!id && !mostrarInputs}
                    disabled={disabledStatus}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <MaterialInputTexto
                    type="text"
                    id="motivoCancelamento"
                    name="motivoCancelamento"
                    label="Motivo do cancelamento"
                    renderIconShowHide={false}
                    defaultValue={motivoCancelamento}
                    onBlur={(e) => setMotivoCancelamento(e.target.value)}
                    ref={register(
                      { name: "motivoCancelamento" },
                      {
                        required: validador
                          ? "Campo motivo do cancelamento é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                    allowClear={!id && !mostrarInputs}
                    disabled={disabledStatus}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box display="flex" justifyContent="end" marginRight={3.5}>
                    <Tooltip title={handleConfirmacao()}>
                      <InfoOutlined
                        className={classes.infoImidiato}
                        fontSize="small"
                      />
                    </Tooltip>
                  </Box>
                  <SelectArredondado
                    id="confirmarId"
                    name="confirmarId"
                    valueKey="id"
                    valueName="descricao"
                    dataSource={confirmacao}
                    label="Cancelamento imediato?"
                    value={confirmarId}
                    placeholder="Cancelamento imediato?"
                    onChange={(e) => {
                      setConfirmarId(e.target.value);
                    }}
                    ref={register(
                      { name: "confirmarId" },
                      {
                        required: validador
                          ? "Campo cancelamento imediato é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                    allowClear={!id && !mostrarInputs}
                    disabled={disabledStatus}
                  />
                </Grid>
                <Grid
                  component={Box}
                  item
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  display={{
                    xl: !mostrarInputs ? "block" : "none",
                    lg: !mostrarInputs ? "block" : "none",
                    md: !mostrarInputs ? "block" : "none",
                    sm: "none",
                    xs: "none"
                  }}
                />
              </>
            )}
          </Grid>
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item lg={3} md={3} sm={5} xs={12}>
              <Botao type="submit" label="Salvar" className={classes.button} />
            </Grid>
            {permisaoReativarBoletaGd && (
              <Grid item lg={3} md={3} sm={5} xs={12}>
                <BotaoForm
                  onClick={() => {
                    setModalReativarBoletaGd(true);
                  }}
                  id="reativaoId"
                  label="Reativação"
                  type="button"
                  style={{ color: "#FA3EB1", border: "1px solid #FA3EB1" }}
                  titleTooltip="Reativar BoletaGD Rejeitada ou Cancelada"
                  styleTooltip={{ sx: { fontSize: "15px" } }}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </form>
    </Loader>
  );
};

export default BoletaCadastroGD;
